import React, { RefObject } from 'react';
import ReactGA from 'react-ga';
import { ModalHotspotProps, HotspotState } from '../types/hotspot';
import './ModalHotspot.css';
import {
  stopAutoRotate,
  startAutoRotate,
  changeCamera,
  getNameById,
  disable,
  enable,
} from '../libs/marzipano';
/*import modalHotspot from '../icon/gallery.svg';
import modalHover from '../icon/gallery-hover.svg';
import modalSingleHotspot from '../icon/modal-single.svg';
import modalSingleHover from '../icon/modal-single-hover.svg';*/
import { ReactComponent as ModalSVG } from '../icon/type=gallery.svg';
import { ReactComponent as VideoSVG } from '../icon/type=video.svg';
import { ReactComponent as T3DSVG } from '../icon/type=3d.svg';
import { ReactComponent as NewSVG } from '../icon/new.svg';
import { ReactComponent as NeuSVG } from '../icon/neu.svg';
import { GlobalVars } from '../libs/utils';
import { createRef } from 'react';
import { DecorData } from '../types/decor';

export default class ModalHotspot extends React.Component<ModalHotspotProps> {
  state: HotspotState = {
    active: false,
    hovered: false,
  };

  playerRef: RefObject<HTMLDivElement> = createRef();
  player: any;
  youtubeReady: boolean = false;

  mounted: boolean = false;
  domElement = createRef<HTMLDivElement>();

  componentDidMount = () => {
    this.mounted = true;
    window.addEventListener('closeAllHotspots', () => {
      this.mounted && this.setState({ active: false });
    });
    if (this.domElement) {
      this.domElement.current?.addEventListener('hotspotRegionOn', () => {
        this.mounted && !this.state.hovered && this.setState({ hovered: true });
      });
      this.domElement.current?.addEventListener('hotspotRegionOff', () => {
        this.mounted && this.state.hovered && this.setState({ hovered: false });
      });
    }
    if (GlobalVars.room !== undefined && GlobalVars.socket && !GlobalVars.controller) {
      GlobalVars.socket.on('activated', this.act);
      GlobalVars.socket.on('deactivated', this.deact);
    }
    if (this.props.info.fullscreen) {
      const id = (this.props.info.videoSrc?.split('/') as any).at(-1);
      this.player = new (window as any).YT.Player('yt-player-' + id, {
        height: '0',
        width: '0',
        videoId: id,
        events: {
          onReady: this.onPlayerReady,
          onStateChange: (event: any) => {
            if (event.data === 0) {
              if (document.fullscreenElement) document.exitFullscreen();
            }
          },
        },
      });

      document.addEventListener(
        'fullscreenchange',
        () => {
          if (!document.fullscreenElement) this.player.stopVideo();
        },
        false,
      );
      document.addEventListener(
        'msfullscreenchange',
        () => {
          if (!document.fullscreenElement) this.player.stopVideo();
        },
        false,
      );
      document.addEventListener(
        'mozfullscreenchange',
        () => {
          if (!document.fullscreenElement) this.player.stopVideo();
        },
        false,
      );
      document.addEventListener(
        'webkitfullscreenchange',
        () => {
          if (!document.fullscreenElement) this.player.stopVideo();
        },
        false,
      );
    }
  };

  onPlayerReady = () => {
    this.youtubeReady = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  act = (hKey: string) => {
    if (this.props.hKey === hKey) this.activate();
  };
  deact = (hKey: string) => {
    if (this.props.hKey === hKey) {
      this.mounted && this.setState({ active: false });
      if (this.props.mode === 'normal') {
        startAutoRotate();
      }
    }
  };

  componentDidUpdate = (prevProps: ModalHotspotProps) => {
    if (GlobalVars.room !== undefined && GlobalVars.socket && !GlobalVars.controller) {
      GlobalVars.socket.off('activated', this.act);
      GlobalVars.socket.off('deactivated', this.deact);
      GlobalVars.socket.on('activated', this.act);
      GlobalVars.socket.on('deactivated', this.deact);
    }
    if (prevProps.animate !== this.props.animate) {
      if (this.props.animate) {
        const duration = this.props.animationDuration ? this.props.animationDuration : 1000;
        changeCamera(this.props.info.yaw, this.props.info.pitch, undefined, duration, () => {
          if (this.props.mode === 'normal') {
            this.props.changeMode('paused');
          }
          setTimeout(() => {
            const label = this.props.info.label ? this.props.info.label : this.props.info.title;
            this.props.changeBarLabel(label);
            this.mounted && this.setState({ active: true });
            this.props.unblockArrows();
          }, 500);
        });
      } else {
        this.mounted && this.setState({ active: false });
      }
    }
    if (prevProps.mode !== this.props.mode && this.props.mode === 'normal') {
      this.mounted && this.setState({ active: false });
    }
  };

  activate = () => {
    const closeAllEvent = new CustomEvent('closeAllHotspots', {
      detail: {},
    });
    window.dispatchEvent(closeAllEvent);
    let night = '';
    if (this.props.night !== undefined) {
      if (this.props.night) night = '-night';
      else night = '-day';
    }
    let title = this.props.info.title;
    if (this.props.info.template === 'modalDetalhada') {
      const decorData: DecorData = this.props.info.decorData
        ? require(`../JSON/${this.props.info.decorData.path}`)[this.props.info.decorData.index]
        : undefined;
      title = decorData.name;
    }
    ReactGA.event({
      category: 'Modal Hotspot',
      action: 'Open',
      label: `${getNameById(this.props.sceneId)}[${title}]${night}`,
    });
    stopAutoRotate();
    disable();
    changeCamera(
      this.props.info.yaw,
      this.props.info.pitch,
      undefined,
      this.props.info.cameraDuration,
      () => {
        const stopDescriptionEvent = new CustomEvent('stopDescription', {
          detail: {},
        });
        window.dispatchEvent(stopDescriptionEvent);
        setTimeout(() => {
          if (this.props.info.fullscreen) {
            this.player.playVideo(); //won't work on mobile
            let iframe: any = document.getElementById(
              'yt-player-' + (this.props.info.videoSrc?.split('/') as any).at(-1),
            );
            let requestFullScreen =
              iframe.requestFullScreen ||
              iframe.mozRequestFullScreen ||
              iframe.webkitRequestFullScreen;
            if (requestFullScreen) {
              requestFullScreen.bind(iframe)();
            }
          } else {
            this.mounted && this.setState({ active: true });
            if (this.props.mode === 'autoplay') {
              this.props.changeMode('paused');
            }
          }
        }, 500);
      },
    );
    if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
      GlobalVars.socket.emit('activate', this.props.hKey);
    }
  };

  render() {
    const activeClass = this.state.active ? 'active' : '';
    const Content = require(`../templates/modals/${this.props.info.template}.template.tsx`).default;

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let compareClass = '';
    if (this.props.left) {
      compareClass = 'left ';
    }
    if (this.props.right) {
      compareClass = 'right ';
    }

    let iconStyle = mobile ? { width: '32px', height: 'auto' } : {};
    iconStyle = mobile && this.state.hovered ? { width: '46px', height: 'auto' } : iconStyle;

    let hotspotIcon = (
      <ModalSVG
        className={this.state.hovered ? 'hovered' : ''}
        style={iconStyle}
        onMouseEnter={() => {
          this.mounted && this.setState({ hovered: true });
        }}
        onMouseLeave={() => {
          this.mounted && this.setState({ hovered: false });
        }}
      />
    );
    if (this.props.info.videoSrc || this.props.info.template === 'modalVideoGaleria') {
      hotspotIcon = (
        <VideoSVG
          className={this.state.hovered ? 'hovered' : ''}
          style={iconStyle}
          onMouseEnter={() => {
            this.mounted && this.setState({ hovered: true });
          }}
          onMouseLeave={() => {
            this.mounted && this.setState({ hovered: false });
          }}
        />
      );
    }
    if (this.props.info.template === 'modalViewer3d') {
      hotspotIcon = (
        <T3DSVG
          className={this.state.hovered ? 'hovered' : ''}
          style={iconStyle}
          onMouseEnter={() => {
            this.mounted && this.setState({ hovered: true });
          }}
          onMouseLeave={() => {
            this.mounted && this.setState({ hovered: false });
          }}
        />
      );
    }

    return (
      <div>
        {this.props.info.fullscreen && (
          <div
            ref={this.playerRef}
            id={'yt-player-' + (this.props.info.videoSrc?.split('/') as any).at(-1)}
          ></div>
        )}
        <div
          ref={this.domElement}
          key={this.props.hKey}
          className={'hotspot-modal ' + compareClass + this.props.sceneId}
          onClick={() => this.activate()}
          onTouchStart={() => {
            stopAutoRotate();
          }}
          onTouchEnd={() => {
            this.mounted && this.setState({ active: false });
            enable();
            if (this.props.mode === 'normal') {
              startAutoRotate();
            }
            if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
              GlobalVars.socket.emit('deactivate', this.props.hKey);
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          {this.props.info.modelData?.new && this.props.info.modelData?.new !== 'neu' && (
            <NewSVG
              className={this.state.hovered ? 'new-label hover' : 'new-label'}
              style={mobile ? { width: '24px' } : {}}
            />
          )}
          {this.props.info.modelData?.new === 'neu' && (
            <NeuSVG
              className={this.state.hovered ? 'new-label hover' : 'new-label'}
              style={mobile ? { width: '24px' } : {}}
            />
          )}
          {/*this.props.info.modelData?.new && (
            <div className="new-label">{this.props.info.modelData.new}</div>
          )*/}
          {hotspotIcon}
        </div>
        <div className={'modal-container ' + activeClass}>
          <div
            className="background"
            onClick={() => {
              if ((this.props.live && this.props.controlling) || !this.props.live) {
                this.mounted && this.setState({ active: false });
                enable();
                if (this.props.mode === 'normal') {
                  startAutoRotate();
                }
                if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
                  GlobalVars.socket.emit('deactivate', this.props.hKey);
                }
              }
            }}
          ></div>
          <Content
            info={this.props.info}
            navBarEnabled={this.props.navBarEnabled}
            mode={this.props.mode}
            changeMode={this.props.changeMode}
            close={() => {
              if ((this.props.live && this.props.controlling) || !this.props.live) {
                this.mounted && this.setState({ active: false });
                enable();
                if (this.props.mode === 'normal') {
                  startAutoRotate();
                }
                if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
                  GlobalVars.socket.emit('deactivate', this.props.hKey);
                }
              }
            }}
            active={this.state.active}
            sceneId={this.props.sceneId}
          />
        </div>
      </div>
    );
  }
}
