import React, { FunctionComponent, useRef } from 'react';
import './login.css';

interface Props {
  loginCallback: CallableFunction;
}

const Login: FunctionComponent<Props> = ({ loginCallback }) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className="login-container" onClick={(e: any) => e.stopPropagation()}>
        <h1>Login</h1>
        <form
          className="login"
          name="login"
          onSubmit={(e: any) => {
            e.preventDefault();
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                email: emailRef.current?.value,
                password: passwordRef.current?.value,
              }),
            };
            fetch('https://twilio.alive.vision/signin', requestOptions)
              .then((response) => response.json())
              .then((data) => {
                if (data.token) {
                  //save token
                  document.cookie = 'jwttoken=' + data.token;
                  loginCallback();
                } else {
                }
              });
            return false;
          }}
        >
          <label>Introduza as suas credenciais para iniciar a videoconferência</label>
          <input
            ref={emailRef}
            className="email"
            type="text"
            id="email"
            placeholder="email"
          ></input>
          <input
            ref={passwordRef}
            className="pass"
            type="password"
            id="pass"
            placeholder="palavra-passe"
          ></input>
          <input className="submit" type="submit" value="LOGIN"></input>
        </form>
      </div>
    </>
  );
};

export default Login;
