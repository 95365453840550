import React from 'react';
import './greeting.scss';

function Greeting(props: { language: string }) {
  let greetings = 'Bem vindo ao nosso assistente virtual.';
  switch (props.language) {
    case 'English':
      greetings = 'Welcome to our virtual assitant';
      break;

    default:
      greetings = 'Bem vindo ao nosso assistente virtual.';
      break;
  }
  return (
    <div className="greeting-container">
      <div className="greeting-text typo-body-chat">{greetings}</div>
    </div>
  );
}

export default Greeting;
