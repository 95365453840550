import React from 'react';
import { LabelHotspotProps, HotspotPerspectiveState } from '../types/hotspot';
import {
  changePerspective,
  stopAutoRotate,
  startAutoRotate,
  changeCamera,
} from '../libs/marzipano';
import './LabelHotspot.css';
//import direction from '../icon/label-lite.svg';
import { ReactComponent as DirectionSVG } from '../icon/label-lite.svg';
import { ReactComponent as Direction2SVG } from '../icon/label-lite2.svg';

export default class LabelHotspot extends React.Component<LabelHotspotProps> {
  state: HotspotPerspectiveState = {
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
  };

  keysPressed: any = [];

  rotationDegreeIncrement = 0.2;

  componentDidMount = () => {
    if (this.props.info.perspective.extraTransforms && this.props.info.perspective.editMode) {
      let tmp = this.props.info.perspective.extraTransforms.trim().split(' ');
      let x = 0;
      let y = 0;
      let z = 0;
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].trim().startsWith('rotateX(')) {
          x = Number(tmp[i].replace(/[^-,0-9,.]/g, ''));
        }
        if (tmp[i].trim().startsWith('rotateY(')) {
          y = Number(tmp[i].replace(/[^-,0-9,.]/g, ''));
        }
        if (tmp[i].trim().startsWith('rotateZ(')) {
          z = Number(tmp[i].replace(/[^-,0-9,.]/g, ''));
        }
      }
      this.setState({ rotateX: x, rotateY: y, rotateZ: z });
    }
  };

  componentDidUpdate = (prevProps: LabelHotspotProps, prevState: HotspotPerspectiveState) => {
    if (prevState !== this.state) {
      const extraTransform = `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`;
      changePerspective(extraTransform, this.props.sceneId, this.props.hKey);
    }
    if (prevProps.animate !== this.props.animate) {
      if (this.props.animate) {
        const duration = this.props.animationDuration ? this.props.animationDuration : 1000;
        changeCamera(this.props.info.yaw, this.props.info.pitch, undefined, duration, () => {
          if (this.props.mode === 'normal') {
            this.props.changeMode('paused');
          }
          this.props.changeBarLabel(this.props.info.title);
          this.props.unblockArrows();
        });
      }
    }
  };

  mouseHoverHandler = () => {
    stopAutoRotate();
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
  };

  keyDown = (event: any) => {
    this.keysPressed[event.key] = true;
    if (this.keysPressed['x'] && event.key === 'ArrowRight') {
      const rotateX = this.state.rotateX + this.rotationDegreeIncrement;
      this.setState({ rotateX: rotateX });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['x'] && event.key === 'ArrowLeft') {
      const rotateX = this.state.rotateX - this.rotationDegreeIncrement;
      this.setState({ rotateX: rotateX });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['y'] && event.key === 'ArrowRight') {
      const rotateY = this.state.rotateY + this.rotationDegreeIncrement;
      this.setState({ rotateY: rotateY });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['y'] && event.key === 'ArrowLeft') {
      const rotateY = this.state.rotateY - this.rotationDegreeIncrement;
      this.setState({ rotateY: rotateY });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['z'] && event.key === 'ArrowRight') {
      const rotateZ = this.state.rotateZ + this.rotationDegreeIncrement;
      this.setState({ rotateZ: rotateZ });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
    if (this.keysPressed['z'] && event.key === 'ArrowLeft') {
      const rotateZ = this.state.rotateZ - this.rotationDegreeIncrement;
      this.setState({ rotateZ: rotateZ });
      console.log(
        `rotateX(${this.state.rotateX}deg) rotateY(${this.state.rotateY}deg) rotateZ(${this.state.rotateZ}deg)`,
      );
    }
  };

  keyUp = (event: any) => {
    delete this.keysPressed[event.key];
  };

  mouseOutHandler = () => {
    startAutoRotate();
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
  };

  render() {
    let compareClass = '';
    if (this.props.left) {
      compareClass = 'left ';
    }
    if (this.props.right) {
      compareClass = 'right ';
    }

    let direction = <DirectionSVG />;
    if (this.props.index) {
      direction = this.props.index % 2 === 0 ? <Direction2SVG /> : <DirectionSVG />;
    }

    return this.props.info.perspective.editMode ? (
      <div
        style={{ zIndex: -1 }}
        key={this.props.hKey}
        id={this.props.hKey}
        className={this.props.info.position + ' hotspot-label ' + compareClass + this.props.sceneId}
      >
        <div
          className="content"
          onMouseEnter={() => {
            this.mouseHoverHandler();
          }}
          onMouseOut={() => {
            this.mouseOutHandler();
          }}
        >
          <h3>{this.props.info.title}</h3>
          <div className="pointer">
            {
              //<img src={direction} alt="Label Hotspot"></img>
            }
            {direction}
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{ zIndex: -1 }}
        key={this.props.hKey}
        className={this.props.info.position + ' hotspot-label ' + this.props.sceneId}
      >
        <div className="content">
          <h3>{this.props.info.title}</h3>
          <div className="pointer">
            {
              //<img src={direction} alt="Label Hotspot"></img>
            }
            {direction}
          </div>
        </div>
      </div>
    );
  }
}
