import React from 'react';
import './modalCovilha.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { ReactComponent as MountainsSVG } from '../../svg/mountains.svg';
import { ReactComponent as CloseSVG } from '../../icon/close.svg';

interface ModalCovhilhaProps extends ModalHotspotProps {
  close: CallableFunction;
}

export default class Modal1 extends React.Component<ModalCovhilhaProps> {
  render() {
    const navBar = this.props.navBarEnabled && !window.orientation ? 'navBar-' : '';

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    if (mobile) {
      return (
        <div className="mobile-modal-covilha">
          <h1>
            {this.props.info.title}
            <CloseSVG onClick={() => this.props.close()} />
          </h1>
          <div className="mobile-content-covilha">
            <div className="mobile-text">
              <p>{this.props.info.description}</p>
              <img
                className="logoCov"
                src={'./assets/img/logo_visitcovilha_200px.png'}
                alt=""
              ></img>
            </div>
            <div className="mobile-modal-img">
              <img src={this.props.info.src} alt=""></img>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={navBar + 'modal-covilha'}>
          <h1>
            {this.props.info.title}
            <CloseSVG onClick={() => this.props.close()} />
          </h1>
          <div className="modal-wrapper">
            <div className="information">
              <p>{this.props.info.description}</p>
            </div>
            <div className="main-img">
              <img src={this.props.info.src} alt=""></img>
            </div>
          </div>
          <MountainsSVG />
        </div>
      );
    }
  }
}
