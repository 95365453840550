import React, { RefObject } from 'react';
import ReactGA from 'react-ga';
import { DecorData, DecorProps, DecorState } from '../types/decor';
import './DecorSelect.css';
import { ReactComponent as DecorSVG } from '../icon/decor-tool.svg';
import { ReactComponent as DecorMobileSVG } from '../icon/decor.svg';
import { ReactComponent as SearchSVG } from '../icon/search.svg';
import { ReactComponent as CloseSVG } from '../icon/close.svg';

export default class DecorSelect extends React.Component<DecorProps> {
  decorData: DecorData[] = require(`../JSON/${this.props.decorDataPath}`);

  state: DecorState = {
    active: false,
    styles: this.decorData.map((dec, index) => {
      return (
        <div
          key={'dec-' + index}
          className="style-container"
          onClick={() => {
            ReactGA.event({
              category: 'Decoration',
              action: 'Clicked',
              label: `${dec.name}`,
            });
            const mapping = dec.mapping !== this.props.currentMappingPath ? dec.mapping : undefined;
            this.props.goToDecor(dec.sceneId, mapping, undefined, dec.lookTo);
            this.setState({ active: false });
          }}
        >
          <img src={dec.mainImage} alt=""></img>
          <div className="over">
            <p>{dec.name}</p>
          </div>
        </div>
      );
    }),
  };

  inputRef: RefObject<HTMLInputElement> = React.createRef();

  search = () => {
    let tmp = this.decorData.map((dec, index) => {
      const params = this.inputRef.current ? this.inputRef.current.value.toLowerCase() : '';
      let contains = false;
      if (dec.characteristics.toLowerCase().includes(params)) contains = true;
      if (dec.name.toLowerCase().includes(params)) contains = true;
      dec.tableware.forEach((t) => {
        if (t.toLowerCase().includes(params)) {
          contains = true;
        }
      });
      dec.decoration.forEach((d) => {
        if (d.toLowerCase().includes(params)) {
          contains = true;
        }
      });
      if (contains) {
        return (
          <div
            key={'dec-' + index}
            className="style-container"
            onClick={() => {
              ReactGA.event({
                category: 'Decoration',
                action: 'Clicked',
                label: `${dec.name}`,
              });
              const mapping =
                dec.mapping !== this.props.currentMappingPath ? dec.mapping : undefined;
              this.props.goToDecor(dec.sceneId, mapping, undefined, dec.lookTo);
              this.setState({ active: false });
            }}
          >
            <img src={dec.mainImage} alt=""></img>
            <div className="over">
              <p>{dec.name}</p>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });

    tmp.filter((el) => el !== null);

    this.setState({ styles: tmp });
  };

  render = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const activeClass = this.state.active ? 'active' : '';

    let searchDecorsLabel = 'pesquisar decorações...';
    switch (this.props.language) {
      case 'English':
        searchDecorsLabel = 'search decorations...';
        break;
    }

    if (mobile) {
      if (this.state.active) {
        return [
          <div
            key="modal-key"
            className="modal-decor"
            onClick={() => {
              this.setState({ active: false });
            }}
          >
            <div className={'decor-container ' + activeClass}>
              <div className="search-bar">
                <SearchSVG />
                <input
                  ref={this.inputRef}
                  type="text"
                  onChange={() => {
                    this.search();
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                  placeholder={searchDecorsLabel}
                ></input>
                <CloseSVG
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({ active: false });
                  }}
                />
              </div>
              <div className="styles-container">{this.state.styles}</div>
            </div>
          </div>,
          <DecorMobileSVG
            key="svg-key"
            className={this.state.active ? 'active' : ''}
            onClick={() => {
              this.setState({ active: !this.state.active });
            }}
          />,
        ];
      }
      return (
        <DecorMobileSVG
          className={this.state.active ? 'active' : ''}
          onClick={() => {
            this.setState({ active: !this.state.active });
          }}
        />
      );
    }
    return (
      <div className="decor">
        <DecorSVG
          className={activeClass}
          onClick={() => {
            this.setState({ active: true });
          }}
        />
        <div className={'decor-container ' + activeClass}>
          <div className="search-bar">
            <SearchSVG />
            <input
              ref={this.inputRef}
              type="text"
              onChange={() => {
                this.search();
              }}
              placeholder={searchDecorsLabel}
            ></input>
            <CloseSVG
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({ active: false });
              }}
            />
          </div>
          <div className="styles-container">{this.state.styles}</div>
        </div>
      </div>
    );
  };
}
