import React from 'react';
import { HotspotState, PAHotspotProps } from '../types/hotspot';
import './PAHotspot.css';
import { changeCamera } from '../libs/marzipano';
import closeBtn from '../icon/close.svg';
import { ReactComponent as PaSVG } from '../icon/point-ask.svg';
import { ReactComponent as PaHoverSVG } from '../icon/point-ask-hover.svg';

export default class PAHotspot extends React.Component<PAHotspotProps> {
  state: HotspotState = {
    active: false,
  };

  componentDidMount = () => {
    window.addEventListener('closeAllHotspots', () => {
      this.setState({ active: false });
    });
  };

  render() {
    const activeClass = this.state.active ? 'active' : '';
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let questionLbl = 'Pergunta';
    switch (this.props.language) {
      case 'English':
        questionLbl = 'Question';
        break;

      default:
        questionLbl = 'Pergunta';
        break;
    }
    return (
      <div style={{ pointerEvents: 'none' }}>
        <div
          style={{ zIndex: 99 }}
          id={'pa-' + this.props.info.scene + '-' + this.props.index}
          className={'hotspot-pa ' + this.props.info.scene}
          onClick={() => {
            const stopDescriptionEvent = new CustomEvent('stopDescription', {
              detail: {},
            });
            window.dispatchEvent(stopDescriptionEvent);
            if (mobile) {
              changeCamera(
                this.props.info.pos.yaw,
                this.props.info.pos.pitch,
                undefined,
                undefined,
                () => {
                  setTimeout(() => {
                    this.setState({ active: true });
                  }, 500);
                },
              );
            } else {
              this.setState({ active: true });
            }
          }}
          onTouchStart={() => {}}
          onTouchEnd={() => {
            this.setState({ active: false });
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          onMouseOut={() => {
            this.setState({ active: false });
          }}
        >
          <PaSVG className="hotspot" />
          <PaHoverSVG className={'hotspot-hover ' + activeClass} />
          {/*<img className={'hotspot-hover ' + activeClass} src={infoHover} alt="Hovered"></img>
          <img className="hotspot" src={infoHotspot} alt="Hotspot"></img>
          <img className={'hotspot-oval ' + activeClass} src={selectedOval} alt="Oval"></img>*/}
          {mobile ? null : (
            <div className={'content ' + activeClass}>
              <h3>{questionLbl}</h3>
              <p>{this.props.info.question}</p>
            </div>
          )}
        </div>
        {mobile ? (
          <div className={'pa-hotspot-container ' + activeClass}>
            <div
              className="background"
              onClick={() => {
                this.setState({ active: false });
              }}
            ></div>
            <div className="mobile-content">
              <h3>
                Pergunta
                <div
                  className="close"
                  onClick={() => {
                    this.setState({ active: false });
                  }}
                >
                  <img src={closeBtn} alt=""></img>
                </div>
              </h3>
              <p>{this.props.info.question}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
