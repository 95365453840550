import React from 'react';
import ReactGA from 'react-ga';
import './ShareLink.css';
import { ReactComponent as ShareSVG } from '../icon/share.svg';
import { ReactComponent as BackSVG } from '../icon/back-oval.svg';
import { encodeHash } from '../libs/utils';
import { ShareLinkProps } from '../types/mainBar';
import { position, getNameById } from '../libs/marzipano';

interface ShareLinkState {
  hovered: boolean;
  tooltip: boolean;
  tooltipTimeout: ReturnType<typeof setTimeout> | undefined;
  shared: boolean;
}

export default class ShareLink extends React.Component<ShareLinkProps> {
  state: ShareLinkState = {
    hovered: false,
    tooltip: false,
    tooltipTimeout: undefined,
    shared: false,
  };

  showTooltip = () => {
    const tmp = setTimeout(() => this.setState({ tooltip: true }), 800);
    this.setState({ tooltipTimeout: tmp });
  };

  clearTooltip = () => {
    if (this.state.tooltipTimeout) {
      clearTimeout(this.state.tooltipTimeout);
    }
    this.setState({ tooltip: false, tooltipTimeout: undefined });
  };

  copyToClipboard = () => {
    const pos = position();
    ReactGA.event({
      category: 'Share Link',
      action: 'Clicked',
      label: getNameById(this.props.spaceId),
    });
    const code = encodeHash(
      this.props.mapping.split('.')[0],
      this.props.sector,
      this.props.spaceId,
      pos.yaw,
      pos.pitch,
      pos.fov,
      String(this.props.timelapseIndex),
      'test',
      'false',
    );

    navigator.clipboard.writeText(window.location.href + '?loc=' + code).then(
      () => {
        this.setState({ shared: true });
        setTimeout(() => {
          this.setState({ shared: false });
        }, 2000);
      },
      () => {
        let alertLbl = 'Não foi possível copiar o link, por favor veja as definições do browser.';
        switch (this.props.language) {
          case 'English':
            alertLbl =
              'It was not possible to copy link to clipboard, please review your browser settings.';
            break;

          default:
            alertLbl = 'Não foi possível copiar o link, por favor veja as definições do browser.';
            break;
        }
        alert(alertLbl);
      },
    );
  };

  render = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let tooltipLbl = 'Partilhe';
    let copiedLink = 'Link copiado para a área de transferências';
    switch (this.props.language) {
      case 'English':
        tooltipLbl = 'Share';
        copiedLink = 'Link has been copied to clipboard';
        break;

      default:
        tooltipLbl = 'Partilhe';
        copiedLink = 'Link copiado para a área de transferências';
        break;
    }

    const backStyle = this.state.hovered ? { opacity: 1 } : { opacity: 0 };

    const tooltipStyle = this.state.tooltip
      ? { opacity: 1, transform: 'translate(-50%, -165%)' }
      : { opacity: 0 };
    const tooltip = (
      <div className="arrow-div" style={tooltipStyle}>
        <div className="info-tooltip">{tooltipLbl}</div>
        <div className="arrow-down"></div>
      </div>
    );

    const sharedStyle = this.state.shared
      ? { opacity: 0.8, transform: 'translate(-50%, -100%)' }
      : { opacity: 0 };

    if (mobile) {
      return null;
    } else {
      return (
        <div>
          <div className="share">
            <ShareSVG
              className="shareButton"
              onClick={() => this.copyToClipboard()}
              onMouseEnter={() => {
                this.showTooltip();
                this.setState({ hovered: true });
              }}
              onMouseLeave={() => {
                this.clearTooltip();
                this.setState({ hovered: false });
              }}
            />
            <BackSVG className="backButton" style={backStyle} />
            {tooltip}
          </div>
          <div className="shared" style={sharedStyle}>
            {copiedLink}
          </div>
        </div>
      );
    }
  };
}
