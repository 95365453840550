import React, { useState, useCallback, useEffect, FunctionComponent, useRef } from 'react';
import ReactGA from 'react-ga';
import Video, { createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import { GlobalVars } from '../libs/utils';
import Room from './Room';
import './VideoChat.css';

interface Props {
  live: boolean;
  user?: any;
}

const VideoChat: FunctionComponent<Props> = ({ live, user }) => {
  const [room, setRoom] = useState<any>(null);
  const [timer, setTimer] = useState<number>(0);
  const [alert, setAlert] = useState<boolean>(false);
  const mounted = useRef<boolean>(false);
  const startDate = useRef<Date>();
  //const [connecting, setConnecting] = useState(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    return () => {
      if (GlobalVars.admin && GlobalVars.socket && room) {
        try {
          const url = `https://twilio.alive.vision/rooms/${room.sid}?status=completed`;
          //const url = `http://localhost:3600/rooms/${room.sid}?status=completed`;
          fetch(url, {
            method: 'POST',
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
  }, [room]);

  useEffect(() => {
    const id = Math.floor(Math.random() * 1000000000 + 1);
    //setConnecting(true);
    let localVideoTrack: any;
    let localAudioTrack: any;

    const getData = async () => {
      const url = 'https://twilio.alive.vision/video/token';
      //const url = 'http://localhost:3000/video/token';
      const data = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          identity: (GlobalVars.identity ? GlobalVars.identity : 'Admin') + '-user' + id,
          room: 'room-' + GlobalVars.room,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => res.json());
      Video.connect(data.token, {
        name: 'room-' + GlobalVars.room,
        tracks: [],
      })
        .then(async (room: any) => {
          if (user && user.data.trialSpecs.active) {
            mounted.current && setTimer(user.data.trialSpecs.maxMeetingMinutes * 60);
            //mounted.current && setTimer(0.5 * 60);
          }
          if (GlobalVars.socket) {
            const participantsLimit = user?.data.trialSpecs.active
              ? user.data.trialSpecs.maxMeetingUsers
              : 1000;
            GlobalVars.socket.emit('participantsLimit', participantsLimit);
          }
          try {
            localVideoTrack = await createLocalVideoTrack();
          } catch (e) {
            console.error(e);
          }
          try {
            localAudioTrack = await createLocalAudioTrack();
          } catch (e) {
            console.error(e);
          }
          localVideoTrack && (await room.localParticipant.publishTrack(localVideoTrack));
          localAudioTrack && (await room.localParticipant.publishTrack(localAudioTrack));

          mounted.current && setRoom(room);
          /*room.on('disconnected', () => {
            const closeCallEvent = new Event('closeCall');
            window.dispatchEvent(closeCallEvent);
          });*/
        })
        .catch((err) => {
          console.log(err);
          GlobalVars.reset();
          //console.error(err);
          //setConnecting(false);
        });
    };

    getData();

    return () => {
      localVideoTrack && localVideoTrack.stop();
      localAudioTrack && localAudioTrack.stop();
    };
  }, [user]);

  useEffect(() => {
    if (user && user.data.trialSpecs.active && room) {
      if (timer >= 0) {
        setTimeout(() => {
          mounted.current && setTimer(timer - 1);
        }, 1000);
      } else {
        if (room) {
          room.disconnect();
          try {
            const url = `https://twilio.alive.vision/rooms/${room.sid}?status=completed`;
            //const url = `http://localhost:3600/rooms/${room.sid}?status=completed`;
            fetch(url, {
              method: 'POST',
            });
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  }, [user, timer, room]);

  const handleLogout = useCallback(() => {
    mounted.current &&
      setRoom((prevRoom: any) => {
        if (prevRoom) {
          prevRoom.localParticipant.tracks.forEach((trackPub: any) => {
            trackPub.track.stop();
          });
          prevRoom.disconnect();
        }
        return null;
      });
  }, []);

  useEffect(() => {
    if (room) {
      startDate.current = new Date();
      const tidyUp = (event: any) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener('pagehide', tidyUp);
      window.addEventListener('beforeunload', tidyUp);
      return () => {
        if (startDate.current && GlobalVars.admin) {
          let currentDate = new Date();
          const date =
            currentDate
              .getDate()
              .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) +
            '/' +
            currentDate
              .getMonth()
              .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) +
            '/' +
            currentDate
              .getFullYear()
              .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
          const hours =
            currentDate
              .getHours()
              .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) +
            ':' +
            currentDate
              .getMinutes()
              .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) +
            ':' +
            currentDate
              .getSeconds()
              .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
          const duration = Math.round((currentDate.valueOf() - startDate.current.valueOf()) / 1000);

          ReactGA.event({
            category: 'Video Chat',
            action: `Start at ${date} ${hours}`,
            label: `User: ${user.data.email}; Duration: ${duration}s; Participants: ${
              room.participants.size + 1
            }`,
          });
        }
        window.removeEventListener('pagehide', tidyUp);
        window.removeEventListener('beforeunload', tidyUp);
      };
    }
  }, [room, user, handleLogout]);

  const participantsLimit = user?.data.trialSpecs.active
    ? user.data.trialSpecs.maxMeetingUsers
    : 1000;

  if (timer === 60) {
    setAlert(true);
  }

  return (
    <>
      {room && (
        <Room
          room={room}
          handleLogout={handleLogout}
          live={live}
          participantsLimit={participantsLimit}
        />
      )}{' '}
      {timer && <div className="timer">{new Date(timer * 1000).toISOString().substr(11, 8)}</div>}
      {alert && (
        <div className="alert-bg" onClick={() => setAlert(false)}>
          <div className="alert">
            <p>Falta 1 minuto para terminar a chamada</p>
            <div className="ok" onClick={() => setAlert(false)}>
              OK
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoChat;
