import React, { useState, FunctionComponent, useEffect, useRef } from 'react';
import './controlBar.css';
import { ReactComponent as MicOnSVG } from '../icon/mic_on.svg';
import { ReactComponent as MicOffSVG } from '../icon/mic_off.svg';
import { ReactComponent as VideoOnSVG } from '../icon/videocam_on.svg';
import { ReactComponent as VideoOffSVG } from '../icon/videocam_off.svg';
import { ReactComponent as PersonAddSVG } from '../icon/group_add.svg';
import { ReactComponent as SyncSVG } from '../icon/sync-screen.svg';
//import { ReactComponent as UnsyncSVG } from '../icon/sync-screen-off.svg';
import { ReactComponent as StopSVG } from '../icon/call_end.svg';
import { ReactComponent as LiveModeSVG } from '../icon/present_to_all.svg';
import { ReactComponent as CloseSVG } from '../icon/close.svg';
import { ReactComponent as MoreHorizontalSVG } from '../icon/more_horizontal.svg';
import { ReactComponent as InfoSVG } from '../icon/info.svg';
import { ReactComponent as CopySVG } from '../icon/content_copy.svg';
import { getLiveUrl, GlobalVars } from '../libs/utils';

interface ControlBarProps {
  mute: boolean;
  camera: boolean;
  muteCallback: CallableFunction;
  cameraCallback: CallableFunction;
  controlling: string;
  identity: string;
  isLocal: boolean;
  live: boolean;
}

const ControlBar: FunctionComponent<ControlBarProps> = ({
  mute,
  camera,
  muteCallback,
  cameraCallback,
  isLocal,
  controlling,
  identity,
  live,
}) => {
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  const [collapse, setCollapse] = useState<boolean>(mobile);
  const [finishSession, setFinishSession] = useState<boolean>(false);
  const [startLive, setstartLive] = useState<boolean>(false);
  const [finishLive, setFinishLive] = useState<boolean>(false);
  const [controlChanged, setControlChanged] = useState<boolean>(false);
  const [invite, setInvite] = useState<boolean>(false);

  const urlRef = useRef<HTMLInputElement>(null);

  const turnOffPrompts = () => {
    setFinishSession(false);
    setstartLive(false);
    setFinishLive(false);
    setControlChanged(false);
    setInvite(false);
  };

  useEffect(() => {
    if (GlobalVars.socket && !GlobalVars.admin) {
      GlobalVars.socket.on('startLive', (start: boolean) => {
        if (start) {
          setstartLive(true);
          setTimeout(() => {
            turnOffPrompts();
          }, 3000);
        } else {
          setFinishLive(true);
          setTimeout(() => {
            turnOffPrompts();
          }, 3000);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (controlling === identity && !GlobalVars.admin) {
      setControlChanged(true);
      setTimeout(() => {
        turnOffPrompts();
      }, 3000);
    }
    if (live && controlling && controlling !== identity && !GlobalVars.admin) {
      setControlChanged(true);
      setTimeout(() => {
        turnOffPrompts();
      }, 3000);
    }
  }, [controlling, identity, live]);

  let liveFeed = <div className="live-feed">Está em videoconferência</div>;
  if (live) {
    if (controlling === identity) {
      liveFeed = <div className="live-feed guide">Está a guiar a visita</div>;
    } else {
      liveFeed = <div className="live-feed guided">Está a ser guiado na visita</div>;
    }
  }

  if (collapse) {
    return (
      <>
        <div className="collapsed">
          <MoreHorizontalSVG
            title="Abir Controlos"
            onClick={() => {
              setCollapse(false);
            }}
          />
        </div>
        {!mobile && liveFeed}
      </>
    );
  } else {
    /*const liveUrl = () => {
      if (GlobalVars.room !== undefined) {
        copyLiveUrl(
          GlobalVars.room,
          () => {
            const copiedLiveUrlEvent = new Event('copiedLiveUrl');
            window.dispatchEvent(copiedLiveUrlEvent);
            console.log('copied');
          },
          () => {
            let alertLbl =
              'Não foi possível copiar o link, por favor veja as definições do browser.';
            alert(alertLbl);
          },
        );
      }
    };*/

    let syncBtn: any = (
      <div className={controlling === identity ? 'btn-wrap green' : 'btn-wrap'}>
        {controlling === identity ? (
          <SyncSVG
            className="green"
            onClick={() => {
              if (GlobalVars.socket && GlobalVars.admin) {
                GlobalVars.socket.emit('changeController', '');
                const stopLiveEvent = new Event('stopLive');
                window.dispatchEvent(stopLiveEvent);
              }
            }}
          />
        ) : (
          <SyncSVG
            className="green"
            onClick={() => {
              if (GlobalVars.socket && GlobalVars.admin) {
                GlobalVars.socket.emit('changeController', identity);
              }
            }}
          />
        )}
      </div>
    );

    if (live && controlling === identity && GlobalVars.admin) {
      syncBtn = (
        <div className={'btn-wrap green'}>
          <LiveModeSVG
            title="Desligar Visita Guiada"
            className="green"
            onClick={() => {
              if (GlobalVars.socket && GlobalVars.admin) {
                GlobalVars.socket.emit('changeController', '');
                const stopLiveEvent = new Event('stopLive');
                window.dispatchEvent(stopLiveEvent);
                setFinishLive(true);
                setTimeout(() => {
                  turnOffPrompts();
                }, 3000);
              }
            }}
          />
        </div>
      );
    }
    if (live && controlling !== identity && GlobalVars.admin) {
      syncBtn = (
        <div className={'btn-wrap'}>
          <SyncSVG
            title="Recuperar Controlo da Visita"
            className="green"
            onClick={() => {
              if (GlobalVars.socket && GlobalVars.admin) {
                GlobalVars.socket.emit('changeController', identity);
                setControlChanged(true);
                setTimeout(() => {
                  turnOffPrompts();
                }, 3000);
              }
            }}
          />
        </div>
      );
    }
    if (!live && GlobalVars.admin) {
      syncBtn = (
        <div className="btn-wrap">
          <LiveModeSVG
            title="Iniciar Visita Guiada"
            onClick={() => {
              setstartLive(true);
            }}
          />
        </div>
      );
    }

    if (!live && !GlobalVars.admin) {
      syncBtn = undefined;
    }

    if (!GlobalVars.admin) {
      syncBtn = undefined;
    }

    const finishBlockPrompt = (
      <div
        className="finish-bg"
        onClick={() => {
          //setFinishSession(false);
          turnOffPrompts();
        }}
      >
        <div className="live-mode-container">
          <InfoSVG />
          <p>
            Para terminar a sessão, primeiro tem de sair do modo <strong>Visita Guiada</strong>
          </p>
          <p>
            Para terminar esta modalidade, pressione novamente o ícone{' '}
            <strong>Visita Guiada</strong>
            <LiveModeSVG />
          </p>
          <div className="buttons">
            <div
              className="cancel"
              onClick={() => {
                //setFinishSession(false);
                turnOffPrompts();
              }}
            >
              CONTINUAR
            </div>
          </div>
        </div>
      </div>
    );

    const finishSessionPrompt = (
      <div className="finish-bg">
        <div className="finish-container">
          <h1>Terminar Sessão</h1>
          <p>Tem a certeza que quer terminar a video conferência</p>
          <div className="buttons">
            <div
              className="cancel"
              onClick={() => {
                //setFinishSession(false);
                turnOffPrompts();
              }}
            >
              Cancelar
            </div>
            <div
              className="terminar"
              onClick={() => {
                //setFinishSession(false);
                turnOffPrompts();
                const closeCallEvent = new Event('closeCall');
                window.dispatchEvent(closeCallEvent);
              }}
            >
              Terminar
            </div>
          </div>
        </div>
      </div>
    );

    const simpleLivePrompt = (
      <div className="finish-bg">
        <div className="simple-live-mode-container">
          Iniciou o modo <strong>Visita Guiada</strong>.
        </div>
      </div>
    );

    const startLivePrompt = (
      <div
        className="finish-bg"
        onClick={() => {
          /*const startLiveEvent = new Event('startLive');
          window.dispatchEvent(startLiveEvent);*/
          //setstartLive(false);
          turnOffPrompts();
        }}
      >
        <div className="live-mode-container" style={!GlobalVars.admin ? { height: '214px' } : {}}>
          <LiveModeSVG />
          <p>
            Iniciou o modo <strong>Visita Guiada</strong>
          </p>
          {GlobalVars.admin && (
            <p>
              Para terminar esta modalidade, pressione novamente o ícone{' '}
              <strong>Visita Guiada</strong>
              <LiveModeSVG />
            </p>
          )}
          <div className="buttons">
            {GlobalVars.admin && (
              <div
                className="cancel"
                onClick={() => {
                  //setstartLive(false);
                  turnOffPrompts();
                }}
              >
                CANCELAR
              </div>
            )}
            <div
              className="continue"
              onClick={() => {
                const startLiveEvent = new Event('startLive');
                window.dispatchEvent(startLiveEvent);
                //setstartLive(false);
                turnOffPrompts();
              }}
            >
              COMPREENDI
            </div>
          </div>
        </div>
      </div>
    );

    const finishLivePrompt = (
      <div
        className="finish-bg"
        onClick={() => {
          //setFinishLive(false);
        }}
      >
        <div className="simple-live-mode-container">
          O modo <strong>Visita Guiada</strong> foi terminado.
        </div>
      </div>
    );

    const controlChangedPrompt = (
      <div
        className="finish-bg"
        onClick={() => {
          //setControlChanged(false);
          //turnOffPrompts();
        }}
      >
        <div className="simple-live-mode-container">
          {controlling && controlling === identity
            ? 'Foi-lhe dado o controlo da visita.'
            : controlling && controlling.split('-')[0] + ' está a controlar a visita.'}
        </div>
      </div>
    );

    const invitationPrompt = (
      <div
        className="finish-bg"
        onClick={() => {
          //setInvite(false)
          turnOffPrompts();
        }}
      >
        <div className="invite-container">
          <h1>Enviar Convite</h1>
          <p>Envie este link para os seus convidados</p>
          <div className="url">
            <input
              ref={urlRef}
              type="text"
              value={
                GlobalVars.room !== undefined
                  ? getLiveUrl(GlobalVars.room)
                  : 'Problema ao gerar o link'
              }
              readOnly
            ></input>
            <CopySVG
              onClick={() => {
                const url = urlRef.current ? urlRef.current.value : '';
                navigator.clipboard.writeText(url).then(
                  () => {
                    /*setShowCopied(true);
                  setInterval(() => {
                    setShowCopied(false);
                  }, 2000);*/
                    //setInvite(false);
                    turnOffPrompts();
                  },
                  () => {
                    let alertLbl =
                      'Não foi possível copiar o link, por favor veja as definições do browser.';
                    alert(alertLbl);
                  },
                );
              }}
            />
          </div>
          <div
            className="copy-btn"
            onClick={() => {
              const url = urlRef.current ? urlRef.current.value : '';
              navigator.clipboard.writeText(url).then(
                () => {
                  /*setShowCopied(true);
                  setInterval(() => {
                    setShowCopied(false);
                  }, 2000);*/
                  //setInvite(false);
                  turnOffPrompts();
                },
                () => {
                  let alertLbl =
                    'Não foi possível copiar o link, por favor veja as definições do browser.';
                  alert(alertLbl);
                },
              );
            }}
          >
            COPIAR LINK
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <div className="video-buttons-container">
          <div className="btn-wrap">
            {mute ? (
              <MicOffSVG
                title="Ligar Microfone"
                onClick={() => {
                  if (isLocal) {
                    muteCallback();
                  }
                }}
              />
            ) : (
              <MicOnSVG
                title="Desligar Microfone"
                onClick={() => {
                  if (isLocal) {
                    muteCallback();
                  }
                }}
              />
            )}
          </div>
          <div className="btn-wrap">
            {camera ? (
              <VideoOffSVG
                title="Ligar Video"
                onClick={() => {
                  if (isLocal) {
                    cameraCallback();
                  }
                }}
              />
            ) : (
              <VideoOnSVG
                title="Desligar Video"
                onClick={() => {
                  if (isLocal) {
                    cameraCallback();
                  }
                }}
              />
            )}
          </div>
          {syncBtn}
          {GlobalVars.admin && (
            <div className="btn-wrap">
              <PersonAddSVG
                title="Obter Link para Adicionar Pessoas"
                onClick={() => {
                  setInvite(true);
                }}
              />
            </div>
          )}
          <div
            className={
              live && (controlling === identity || GlobalVars.admin)
                ? 'btn-wrap gray'
                : 'btn-wrap red'
            }
          >
            <StopSVG
              title="Desligar Chamada"
              onClick={() => {
                setFinishSession(true);
              }}
            />
          </div>
          <div className="collapse">
            <CloseSVG
              title="Minimizar Controlos"
              onClick={() => {
                setCollapse(true);
              }}
            />
          </div>
        </div>
        {!mobile && liveFeed}
        {finishSession &&
          (!live || (controlling !== identity && !GlobalVars.admin)) &&
          finishSessionPrompt}
        {finishSession &&
          live &&
          (GlobalVars.admin || controlling === identity) &&
          finishBlockPrompt}
        {startLive && GlobalVars.admin && startLivePrompt}
        {startLive && !GlobalVars.admin && simpleLivePrompt}
        {finishLive && finishLivePrompt}
        {controlChanged && !startLive && controlChangedPrompt}
        {invite && invitationPrompt}
      </div>
    );
  }
};

export default ControlBar;
