import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import './lobby.css';
import { GlobalVars } from '../libs/utils';
import { ReactComponent as MicOnSVG } from '../icon/mic_on.svg';
import { ReactComponent as MicOffSVG } from '../icon/mic_off.svg';
import { ReactComponent as VideoOnSVG } from '../icon/videocam_on.svg';
import { ReactComponent as VideoOffSVG } from '../icon/videocam_off.svg';

interface Props {
  startCall: CallableFunction;
}

const Lobby: FunctionComponent<Props> = ({ startCall }) => {
  //const [showCopied, setShowCopied] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream>();
  const [audio, setAudio] = useState<boolean>(true);
  const [video, setVideo] = useState<boolean>(false);
  const nameRef = useRef<HTMLInputElement>(null);
  //const urlRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  useEffect(() => {
    const getMedia = async () => {
      try {
        let streamTmp = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        setVideo(true);
        setStream(streamTmp);
        if (videoRef.current) {
          videoRef.current.srcObject = streamTmp;
          videoRef.current.onloadedmetadata = () => {
            videoRef.current?.play();
          };
        }
      } catch (err) {
        console.log(err);
        setVideo(false);
      }
    };
    if (!stream) getMedia();

    return () => {
      stream?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [stream]);

  return (
    <>
      <div className="lby-container" onClick={(e: any) => e.stopPropagation()}>
        <h1>Juntar-se à visita</h1>
        {!mobile && (
          <div className="video">
            {stream && (
              <video
                ref={videoRef}
                style={video ? { visibility: 'visible' } : { visibility: 'hidden' }}
                playsInline={true}
                webkit-playsinline
              />
            )}
          </div>
        )}
        <div className="video-buttons">
          <div className="btn" onClick={() => setAudio(!audio)}>
            {audio ? <MicOnSVG /> : <MicOffSVG />}
          </div>
          <div
            className="btn"
            onClick={() => {
              if (stream) setVideo(!video);
            }}
          >
            {video ? <VideoOnSVG /> : <VideoOffSVG />}
          </div>
        </div>
        <form
          className="invitation"
          name="invitation"
          onSubmit={(e: any) => {
            e.preventDefault();
            if (nameRef.current && nameRef.current.value) {
              GlobalVars.identity = nameRef.current.value;
              GlobalVars.startMuted = !audio;
              GlobalVars.startOff = !video;
              startCall(nameRef.current.value);
            }

            if (nameRef.current && !nameRef.current.value) {
              nameRef.current.required = true;
            }

            return false;
          }}
        >
          <label htmlFor="adminName">Por favor introduza o seu nome</label>
          <input
            ref={nameRef}
            className="name"
            type="text"
            id="adminName"
            placeholder="Nome"
          ></input>
          <input className="enter-btn" type="submit" value="ENTRAR NA VISITA"></input>
        </form>
      </div>
    </>
  );
};

export default Lobby;
