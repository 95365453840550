import React from 'react';
import ReactGA from 'react-ga';
import { HotspotState, InfoHotspotProps } from '../types/hotspot';
import './InfoHotspot.css';
import {
  stopAutoRotate,
  startAutoRotate,
  changeCamera,
  getNameById,
  disable,
  enable,
} from '../libs/marzipano';
/*import selectedOval from '../icon/oval.svg';
import infoHotspot from '../icon/info.svg';
import infoHover from '../icon/info-hover.svg';*/
import { ReactComponent as InfoSVG } from '../icon/type=info.svg';
import { ReactComponent as CloseSVG } from '../icon/close.svg';
import { GlobalVars } from '../libs/utils';
import { createRef } from 'react';

export default class InfoHotspot extends React.Component<InfoHotspotProps> {
  state: HotspotState = {
    active: false,
  };

  mounted: boolean = false;
  domElement = createRef<HTMLDivElement>();

  componentDidMount = () => {
    this.mounted = true;
    window.addEventListener('closeAllHotspots', () => {
      this.mounted && this.setState({ active: false });
    });
    if (this.domElement) {
      this.domElement.current?.addEventListener('hotspotRegionOn', () => {
        this.mounted && !this.state.hovered && this.setState({ hovered: true });
      });
      this.domElement.current?.addEventListener('hotspotRegionOff', () => {
        this.mounted && this.state.hovered && this.setState({ hovered: false });
      });
    }
    if (GlobalVars.room !== undefined && GlobalVars.socket && !GlobalVars.controller) {
      GlobalVars.socket.on('activated', this.act);
      GlobalVars.socket.on('deactivated', this.deact);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  act = (hKey: string) => {
    if (this.props.hKey === hKey) this.activateContent();
  };
  deact = (hKey: string) => {
    if (this.props.hKey === hKey) this.deactivateContent();
  };

  componentDidUpdate = (prevProps: InfoHotspotProps) => {
    if (GlobalVars.room !== undefined && GlobalVars.socket && !GlobalVars.controller) {
      GlobalVars.socket.off('activated', this.act);
      GlobalVars.socket.off('deactivated', this.deact);
      GlobalVars.socket.on('activated', this.act);
      GlobalVars.socket.on('deactivated', this.deact);
    }
    if (prevProps.animate !== this.props.animate) {
      if (this.props.animate) {
        const duration = this.props.animationDuration ? this.props.animationDuration : 1000;
        const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        );
        const p = mobile ? this.props.info.pitch : this.props.info.pitch;
        changeCamera(this.props.info.yaw, p, undefined, duration, () => {
          setTimeout(() => {
            if (this.props.mode === 'normal') {
              this.props.changeMode('paused');
            }
            const label = this.props.info.label ? this.props.info.label : this.props.info.title;
            this.props.changeBarLabel(label);
            this.mounted && this.setState({ active: true });
            this.props.unblockArrows();
          }, 500);
        });
      } else {
        this.mounted && this.setState({ active: false });
      }
    }
    if (prevProps.mode !== this.props.mode && this.props.mode === 'normal') {
      this.mounted && this.setState({ active: false });
    }
  };

  activateContent = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    const closeAllEvent = new CustomEvent('closeAllHotspots', {
      detail: {},
    });
    window.dispatchEvent(closeAllEvent);
    let night = '';
    if (this.props.night !== undefined) {
      if (this.props.night) night = '-night';
      else night = '-day';
    }
    if (!this.state.active) {
      ReactGA.event({
        category: 'Info Hotspot',
        action: 'Open',
        label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]${night}`,
      });
      stopAutoRotate();
      disable();
      if (mobile) {
        changeCamera(this.props.info.yaw, this.props.info.pitch, undefined, undefined, () => {
          const stopDescriptionEvent = new CustomEvent('stopDescription', {
            detail: {},
          });
          window.dispatchEvent(stopDescriptionEvent);
          setTimeout(() => {
            this.mounted && this.setState({ active: true });
            if (this.props.mode === 'autoplay') {
              this.props.changeMode('paused');
            }
          }, 500);
        });
      } else {
        const stopDescriptionEvent = new CustomEvent('stopDescription', {
          detail: {},
        });
        window.dispatchEvent(stopDescriptionEvent);
        this.mounted && this.setState({ active: true });
        if (this.props.mode === 'autoplay') {
          this.props.changeMode('paused');
        }
      }

      if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
        GlobalVars.socket.emit('activate', this.props.hKey);
      }
    }
  };

  deactivateContent = () => {
    this.mounted && this.setState({ active: false });
    enable();
    if (this.props.mode === 'normal') {
      startAutoRotate();
    }
    if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
      GlobalVars.socket.emit('deactivate', this.props.hKey);
    }
  };

  render = () => {
    const activeClass = this.state.active ? 'active' : '';
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let contentStyle = { width: '22.9vw' };
    if (this.props.info.contentSize === 'large') {
      contentStyle = { width: '36.64vw' };
    }

    let compareClass = '';
    if (this.props.left) {
      compareClass = 'left ';
    }
    if (this.props.right) {
      compareClass = 'right ';
    }

    let iconStyle = mobile ? { width: '32px', height: 'auto' } : {};
    iconStyle = mobile && this.state.hovered ? { width: '46px', height: 'auto' } : iconStyle;

    return (
      <div style={{ pointerEvents: 'none' }}>
        <div
          ref={this.domElement}
          style={{ zIndex: 99 }}
          key={this.props.hKey}
          className={'hotspot-info ' + compareClass + this.props.sceneId}
        >
          {/*<img className={'hotspot-hover ' + activeClass} src={infoHover} alt="Hovered"></img>
          <img className="hotspot" src={infoHotspot} alt="Hotspot"></img>
          <img className={'hotspot-oval ' + activeClass} src={selectedOval} alt="Oval"></img>*/}
          <InfoSVG
            className={this.state.hovered ? 'hovered' : ''}
            onMouseEnter={() => {
              if (!this.props.info.linkToWebsite && !mobile) {
                this.activateContent();
              }
            }}
            onMouseLeave={() => {
              if (!this.props.info.linkToWebsite && !mobile) {
                this.deactivateContent();
              }
            }}
            onClick={() => {
              if (mobile) {
                this.activateContent();
              }
            }}
            style={iconStyle}
          />
          {mobile ? null : (
            <div className={'content ' + activeClass} style={contentStyle}>
              <h3>
                {this.props.info.title}
                {/*<div
                  className="close"
                  onClick={() => {
                    this.mounted && this.setState({ active: false });
                    if (this.props.mode === 'normal') {
                      startAutoRotate();
                    }
                  }}
                >
                  <img src={closeBtn} alt=""></img>
                </div>*/}
              </h3>
              <p>{this.props.info.text}</p>
              {this.props.info.linkToWebsite ? (
                <div
                  className="button-link"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Info Hotspot',
                      action: `Open Store`,
                      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                    });
                    window.open(this.props.info.linkToWebsite?.url);
                    this.mounted && this.setState({ active: false });
                    if (this.props.mode === 'normal') {
                      startAutoRotate();
                    }
                  }}
                >
                  {this.props.info.linkToWebsite.label}
                </div>
              ) : null}
              {this.props.info.contactInfo ? (
                <p
                  className="contact-info"
                  style={{ pointerEvents: 'all' }}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Info Hotspot',
                      action: 'Sent Email',
                      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                    });
                    window.open('mailto:diana.pinheiro@arvorecoop.pt');
                  }}
                >
                  {this.props.info.contactInfo}
                </p>
              ) : null}
            </div>
          )}
        </div>
        {mobile ? (
          <div className={'info-hotspot-container ' + activeClass}>
            <div
              className="background"
              onClick={() => {
                this.mounted && this.setState({ active: false });
                if (this.props.mode === 'normal') {
                  startAutoRotate();
                }
              }}
            ></div>
            <div className="mobile-content">
              <h3>
                {this.props.info.title}
                <div
                  className="close"
                  onClick={() => {
                    /*this.mounted && this.setState({ active: false });
                    if (this.props.mode === 'normal') {
                      startAutoRotate();
                    }*/
                    this.deactivateContent();
                  }}
                >
                  <CloseSVG />
                </div>
              </h3>
              <p
                style={
                  this.props.info.linkToWebsite || this.props.info.contactInfo
                    ? { height: '50%' }
                    : {}
                }
              >
                {this.props.info.text}
              </p>
              {this.props.info.linkToWebsite ? (
                <div
                  className="button-link"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Info Hotspot',
                      action: `Open Store`,
                      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                    });
                    window.open(this.props.info.linkToWebsite?.url);
                    this.mounted && this.setState({ active: false });
                    if (this.props.mode === 'normal') {
                      startAutoRotate();
                    }
                  }}
                >
                  {this.props.info.linkToWebsite.label}
                </div>
              ) : null}
              {this.props.info.contactInfo ? (
                <p
                  className="contact-info"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Info Hotspot',
                      action: 'Sent Email',
                      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                    });
                    window.open('mailto:diana.pinheiro@arvorecoop.pt');
                  }}
                >
                  {this.props.info.contactInfo}
                </p>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  };
}
