import React from 'react';
import ReactGA from 'react-ga';
import { ArticleHotspotProps } from '../types/hotspot';
import './ArticleHotspot.css';
import { ReactComponent as ArticleSVG } from '../icon/type=article.svg';
import { getNameById } from '../libs/marzipano';

export default class ArticleHotspot extends React.Component<ArticleHotspotProps> {
  mounted: boolean = false;

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let compareClass = '';
    if (this.props.left) {
      compareClass = 'left ';
    }
    if (this.props.right) {
      compareClass = 'right ';
    }

    return (
      <div style={{ pointerEvents: 'none' }}>
        <div
          style={{ zIndex: 99 }}
          key={this.props.hKey}
          className={'hotspot-article ' + compareClass + this.props.sceneId}
        >
          <ArticleSVG
            style={mobile ? { width: '24px', height: '24px' } : {}}
            onClick={() => {
              ReactGA.event({
                category: 'Article Hotspot',
                action: 'Clicked',
                label: `${getNameById(this.props.sceneId)}[${this.props.info.document}]`,
              });
              window.open(this.props.info.document);
            }}
          />
        </div>
      </div>
    );
  };
}
