import React from 'react';
import './modalTexto.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { ReactComponent as CloseSVG } from '../../icon/close.svg';
import { ReactComponent as ImageSVG } from '../../icon/image.svg';
import { ReactComponent as InfoSVG } from '../../icon/info.svg';

interface ModalTextProps extends ModalHotspotProps {
  close: CallableFunction;
}

interface ModalTextState {
  mode: string;
}

export default class Modal1 extends React.Component<ModalTextProps> {
  state: ModalTextState = {
    mode: 'image',
  };
  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    if (mobile) {
      let main;
      switch (this.state.mode) {
        case 'image':
          main = (
            <div className="image">
              <div className="container">
                <img
                  src={this.props.info.src}
                  alt=""
                  onClick={() => this.setState({ mode: 'fullscreen' })}
                ></img>
              </div>
            </div>
          );
          break;
        case 'info':
          main = (
            <div className="info">
              <p>{this.props.info.description}</p>
            </div>
          );
          break;
      }
      if (this.state.mode === 'fullscreen') {
        return (
          <div className="full">
            <img src={this.props.info.src} alt=""></img>
            <CloseSVG className="full-close" onClick={() => this.setState({ mode: 'image' })} />
          </div>
        );
      }
      return (
        <div className="mobile-modal-text">
          <div className="header">
            <h3>{this.props.info.title}</h3>
            <div className="svg-container">
              <CloseSVG
                onClick={() => {
                  this.props.close();
                }}
              />
            </div>
          </div>
          {main}
          <div className="footer">
            <ImageSVG
              className={this.state.mode === 'image' ? 'active' : ''}
              onClick={() => {
                this.setState({ mode: 'image' });
              }}
            />
            <InfoSVG
              className={this.state.mode === 'info' ? 'active' : ''}
              onClick={() => {
                this.setState({ mode: 'info' });
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={'modal-text'}>
          <div className="head">
            <h1>{this.props.info.title}</h1>
            <div className="svg-container">
              <CloseSVG
                onClick={() => {
                  this.props.close();
                }}
              />
            </div>
          </div>
          <div className="content">
            <img src={this.props.info.src} alt=""></img>
            <p>{this.props.info.description}</p>
          </div>
        </div>
      );
    }
  }
}
