import * as React from 'react';
import './messages.scss';

export interface IAppProps {
  messages: { sid: number; sender: string; text: string }[] | undefined;
}

export default function Messages(props: IAppProps) {
  const bottom = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (bottom && bottom.current) bottom.current.scrollIntoView();
  }, [props.messages]);

  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  return (
    <div className={mobile ? 'mob-messages-container' : 'messages-container'}>
      {props.messages &&
        props.messages.map((item) => {
          const tmp = item.text.split('\n\n');
          if (tmp.length === 2) {
            var reg = new RegExp(
              '([a-zA-Zd]+://)?((w+:w+@)?([a-zA-Zd.-]+.[A-Za-z]{2,4})(:d+)?(/.*)?)',
              'i',
            );
            if (reg.test(tmp[1])) {
              return (
                <div
                  key={item.sid}
                  className={item.sender + '-message chat-message typo-body-chat'}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    window.open(tmp[1].trim());
                  }}
                >
                  {'Clique aqui para ver o link partilhado'}
                </div>
              );
            }
          }
          return (
            <div key={item.sid} className={item.sender + '-message chat-message typo-body-chat'}>
              {item.text}
            </div>
          );
        })}
      <div ref={bottom} />
    </div>
  );
}
