import React from 'react';
import './logo.css';
import { LogoProps } from '../types/logo';
import { ReactComponent as BackSVG } from '../icon/back.svg';
import { ReactComponent as BackOvalSVG } from '../icon/back-oval.svg';

export const Logo = (props: LogoProps) => {
  //const className = props.active ? 'back-btn' : 'back-btn inactive';
  /*return (
    <div className="logo-container">
      {props.back ? (
        <div className={className}>
          <img className="back" src={props.back} alt={props.back}></img>
          <img
            className="back-background"
            src={props.background}
            alt={props.background}
            onClick={() => {
              if (props.backFunction) {
                let code;
                if (window.location.search) {
                  const urlParams = new URLSearchParams(window.location.search);
                  code = urlParams.get('loc');
                  if (code) {
                    window.location.href = window.location.href.split('?')[0];
                  }
                }
                props.backFunction();
              }
            }}
          ></img>
        </div>
      ) : null}
      <img className="logo" src={props.logo} alt={props.logo}></img>
    </div>
  );*/
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
  return (
    <div className="logo-container">
      <div className="back-container">
        <BackSVG style={mobile ? { opacity: 1, width: '24px', height: '24px' } : { opacity: 1 }} />
        <BackOvalSVG
          style={mobile ? { width: '24px', height: '24px' } : {}}
          onClick={() => {
            if (props.backFunction) {
              let code;
              if (window.location.search) {
                const urlParams = new URLSearchParams(window.location.search);
                code = urlParams.get('loc');
                if (code) {
                  window.location.href = window.location.href.split('?')[0];
                }
                const language = urlParams.get('language');
                if (language) {
                  window.location.replace(window.location.pathname);
                }
              }
              props.backFunction();
            }
          }}
        />
      </div>
      <img
        className="main-logo"
        src={props.logo}
        alt={props.logo}
        style={mobile ? { width: '114px' } : {}}
      ></img>
    </div>
  );
};
