import React from 'react';
import './map1.template.css';
import { MapProps } from '../../types/maps';
import { ReactSVG } from 'react-svg';
import { arrayEquals } from '../../libs/utils';

interface MapState {
  title: string | null;
  background: string;
  svgRendered: boolean;
  active: boolean;
}

export default class Map1 extends React.Component<MapProps> {
  state: MapState = {
    title: null,
    background: 'transparent',
    svgRendered: false,
    active: false,
  };

  componentDidMount() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      window.addEventListener('displayMap', ((e: CustomEvent) => {
        this.setState({ active: e.detail.display });
      }) as EventListener);
    }
    setTimeout(() => this.setState({ svgRendered: true }), 300);
  }

  componentDidUpdate(prevProps: MapProps) {
    if (
      prevProps.selected !== this.props.selected &&
      prevProps.sectorsData === this.props.sectorsData
    ) {
      prevProps.sectorsData[prevProps.selected.sectorIndex].spaces.map((space) => {
        if (space.mapId) {
          const spacePath = document.getElementById(space.mapId);
          if (spacePath) {
            spacePath.onclick = () => {
              return false;
            };
            spacePath.classList.remove('hoverable');
            spacePath.classList.remove('selected');
            spacePath.onmouseover = () => {
              return false;
            };
            spacePath.onmouseout = () => {
              return false;
            };
          }
        }
        return true;
      });
      this.props.sectorsData[this.props.selected.sectorIndex].spaces.map((space) => {
        if (space.mapId) {
          const spacePath = document.getElementById(space.mapId);
          if (spacePath) {
            spacePath.onclick = () => this.props.click(space.id, space.type);
            spacePath.classList.add('hoverable');
            spacePath.onmouseover = () => {
              this.setState({
                title: space.name,
                background: 'rgba(var(--main-color), 0.5)',
              });
            };

            spacePath.onmouseout = () => {
              this.setState({
                title: null,
                background: 'transparent',
              });
            };

            const equalId = Array.isArray(this.props.selected.space)
              ? arrayEquals(this.props.selected.space, space.id)
              : space.id === this.props.selected.space;
            if (equalId) {
              spacePath.classList.remove('hoverable');
              spacePath.classList.add('selected');
            } else {
              spacePath.classList.add('hoverable');
              spacePath.classList.remove('selected');
            }
          }
        }
        return true;
      });
    }
    this.props.sectorsData[this.props.selected.sectorIndex].spaces.map((space) => {
      if (space.mapId) {
        const spacePath = document.getElementById(space.mapId);
        if (spacePath) {
          const equalId = Array.isArray(this.props.selected.space)
            ? arrayEquals(this.props.selected.space, space.id)
            : space.id === this.props.selected.space;
          if (equalId) {
            spacePath.classList.remove('hoverable');
            spacePath.classList.add('selected');
          } else {
            spacePath.classList.add('hoverable');
            spacePath.classList.remove('selected');
          }
          if (this.state.svgRendered) {
            spacePath.onclick = () => this.props.click(space.id, space.type);
            spacePath.classList.add('hoverable');
            spacePath.onmouseover = () => {
              this.setState({
                title: space.name,
                background: 'rgba(var(--main-color), 0.5)',
              });
            };

            spacePath.onmouseout = () => {
              this.setState({
                title: null,
                background: 'transparent',
              });
            };
            this.setState({ svgRendered: false });
          }
        }
      }
      return true;
    });
  }

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const cname = this.props.active ? 'map-container active' : 'map-container';
    let title = this.state.title
      ? this.state.title
      : this.props.sectorsData[this.props.selected.sectorIndex].spaces.find((space) => {
          const equalId = Array.isArray(this.props.selected.space)
            ? arrayEquals(this.props.selected.space, space.id)
            : space.id === this.props.selected.space;
          if (equalId) return space;
          return null;
        })?.name;
    title = title ? title : '';

    const sectors = this.props.sectorsData.map((sector, index) => {
      if (sector.name === this.props.sectorsData[this.props.selected.sectorIndex].name) {
        return (
          <p key={`sector-${index}`} className={'active'}>
            {sector.name}
          </p>
        );
      } else {
        return (
          <p
            key={`sector-${index}`}
            className={''}
            onClick={() => {
              if (this.props.sectorClick) this.props.sectorClick(index, sector.sectorStart);
            }}
          >
            {sector.name}
          </p>
        );
      }
    });

    /*const svgOverlay = require(`./${
      this.props.sectorsData[this.props.selected.sectorIndex].map?.svg
    }.svg.tsx`).SvgOverlay;*/
    const svgOverlayPath = `./assets/svg/${
      this.props.sectorsData[this.props.selected.sectorIndex].map?.svg
    }.svg`;

    const activeClass = this.state.active ? 'active' : '';

    if (mobile) {
      return (
        <div className={'mobile-map-container ' + activeClass}>
          <div className="background"></div>
          <div className={'mobile-map'}>
            <div
              style={{
                background: `url(${
                  this.props.sectorsData[this.props.selected.sectorIndex].map?.img
                }) no-repeat center`,
                backgroundSize: 'contain',
              }}
              className="map"
            >
              <ReactSVG renumerateIRIElements={false} src={svgOverlayPath} />
            </div>
            <div className="sector-container">{sectors}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={cname}>
          <h3 style={{ background: this.state.background }}>{title}</h3>
          <div className="info-container">
            <div
              style={{
                background: `url(${
                  this.props.sectorsData[this.props.selected.sectorIndex].map?.img
                }) no-repeat center`,
                backgroundSize: 'contain',
              }}
              className="map"
            >
              <ReactSVG renumerateIRIElements={false} src={svgOverlayPath} />
            </div>
            <div className="sector-container">{sectors}</div>
          </div>
        </div>
      );
    }
  }
}
