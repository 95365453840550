import React from 'react';
import ReactGA from 'react-ga';
import './PanoInfo.css';
import { ReactComponent as InfoDeskSVG } from '../icon/info.svg';
import { ReactComponent as BackSVG } from '../icon/back-oval.svg';
import { ReactComponent as CloseSVG } from '../icon/close.svg';
import { ReactComponent as PersonSVG } from '../icon/person.svg';
import { ReactComponent as GroupSVG } from '../icon/groups.svg';
import { ReactComponent as CalendarSVG } from '../icon/calendar.svg';
import { ReactComponent as LocationSVG } from '../icon/location.svg';
import { PanoInfoProps, PanoInfoState } from '../types/mainBar';
import { startAutoRotate, stopAutoRotate } from '../libs/marzipano';

export default class PanoInfo extends React.Component<PanoInfoProps> {
  state: PanoInfoState = {
    descriptionActive: false,
    hovered: false,
    tooltip: false,
    tooltipTimeout: undefined,
    knowMoreActive: false,
  };

  mounted: boolean = false;

  componentDidMount = () => {
    this.mounted = true;
    window.addEventListener('stopDescription', () => {
      this.mounted &&
        this.setState(
          {
            descriptionActive: false,
            hovered: false,
            tooltip: false,
            tooltipTimeout: undefined,
            knowMoreActive: false,
          },
          () => {
            startAutoRotate();
          },
        );
    });
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    let desc = this.props.description.description;
    let knowMoreBtn = null;
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let knowMoreLbl = 'SABER MAIS';
    let tooltipLbl = 'Informações';
    switch (this.props.language) {
      case 'English':
        knowMoreLbl = 'SEE MORE';
        tooltipLbl = 'Informations';
        break;

      default:
        knowMoreLbl = 'SABER MAIS';
        tooltipLbl = 'Informações';
        break;
    }

    if (this.props.description.description.length > 360) {
      const tmp = this.props.description.description.match(/.{1,360}(\s|$)/g);
      desc = tmp ? tmp[0].trim() + '...' : 'null';
      knowMoreBtn = (
        <div
          className="know-more-btn"
          onClick={() => {
            this.mounted &&
              this.setState({ knowMoreActive: true }, () => {
                ReactGA.event({
                  category: 'Quick Information',
                  action: "Clicked 'See More'",
                  label: `${
                    Array.isArray(this.props.sceneId)
                      ? this.props.sceneId[this.props.timelapseIndex]
                      : this.props.sceneId
                  }`,
                });
                stopAutoRotate();
              });
          }}
        >
          {knowMoreLbl}
        </div>
      );
    }
    const content = this.state.descriptionActive ? (
      <div className="pano-info-container">
        <p>{desc}</p>
        <div className="metadata">
          {this.props.description.author ? (
            <div className="author">
              <PersonSVG />
              <p>
                image by <b>{this.props.description.author}</b>
              </p>
            </div>
          ) : null}
          {this.props.description.date ? (
            <div className="date">
              <CalendarSVG />
              <p>{this.props.description.date}</p>
            </div>
          ) : null}
          {this.props.description.coords ? (
            <div className="coords">
              <LocationSVG />
              <p>{this.props.description.coords}</p>
            </div>
          ) : null}
          {this.props.description.capacity ? (
            <div className="coords">
              <GroupSVG />
              <p>{this.props.description.capacity}</p>
            </div>
          ) : null}
        </div>
        {knowMoreBtn}
      </div>
    ) : null;

    const backStyle = this.state.hovered ? { opacity: 1 } : { opacity: 0 };

    const tooltipStyle = this.state.tooltip
      ? { opacity: 1, transform: 'translate(-50%, -160%)' }
      : { opacity: 0 };
    const tooltip =
      this.state.descriptionActive || this.state.knowMoreActive ? null : (
        <div className="arrow-div" style={tooltipStyle}>
          <div className="info-tooltip">{tooltipLbl}</div>
          <div className="arrow-down"></div>
        </div>
      );

    if (mobile) {
      return this.props.mobileActive ? (
        <div className="mobile-know-more-panel">
          <CloseSVG onClick={() => this.props.displayInfo()} />
          <div className="mobile-know-more-info">
            <h1>{this.props.title}</h1>
            <p>{this.props.description.description}</p>
            <div className="mobile-metadata metadata">
              {this.props.description.author ? (
                <div className="author">
                  <PersonSVG />
                  <p>
                    image by <b>{this.props.description.author}</b>
                  </p>
                </div>
              ) : null}
              {this.props.description.date ? (
                <div className="date">
                  <CalendarSVG />
                  <p>{this.props.description.date}</p>
                </div>
              ) : null}
              {this.props.description.coords ? (
                <div className="coords">
                  <LocationSVG />
                  <p>{this.props.description.coords}</p>
                </div>
              ) : null}
              {this.props.description.capacity ? (
                <div className="coords">
                  <GroupSVG />
                  <p>{this.props.description.capacity}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null;
    } else {
      return (
        <div>
          {this.state.knowMoreActive ? (
            <div className="know-more-panel">
              <CloseSVG
                onClick={() =>
                  this.mounted &&
                  this.setState({ knowMoreActive: false, descriptionActive: false }, () => {
                    startAutoRotate();
                  })
                }
              />
              <div className="know-more-info">
                <h1>{this.props.title}</h1>
                <p>{this.props.description.description}</p>
                <div className="metadata">
                  {this.props.description.author ? (
                    <div className="author">
                      <PersonSVG />
                      <p>
                        image by <b>{this.props.description.author}</b>
                      </p>
                    </div>
                  ) : null}
                  {this.props.description.date ? (
                    <div className="date">
                      <CalendarSVG />
                      <p>{this.props.description.date}</p>
                    </div>
                  ) : null}
                  {this.props.description.coords ? (
                    <div className="coords">
                      <LocationSVG />
                      <p>{this.props.description.coords}</p>
                    </div>
                  ) : null}
                  {this.props.description.capacity ? (
                    <div className="coords">
                      <GroupSVG />
                      <p>{this.props.description.capacity}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className="pano-info">
            <div className="pano-info-btn">
              <InfoDeskSVG
                className="p-info"
                onClick={() => {
                  this.mounted &&
                    !this.state.descriptionActive &&
                    ReactGA.event({
                      category: 'Quick Information',
                      action: 'Open',
                      label: `${
                        Array.isArray(this.props.sceneId)
                          ? this.props.sceneId[this.props.timelapseIndex]
                          : this.props.sceneId
                      }`,
                    });
                  this.mounted &&
                    this.setState({ descriptionActive: !this.state.descriptionActive });
                }}
                onMouseEnter={() => {
                  //const tmp = setTimeout(() => this.mounted && this.setState({ tooltip: true }), 800);
                  this.mounted && this.setState({ hovered: true, tooltipTimeout: undefined });
                }}
                onMouseLeave={() => {
                  if (this.state.tooltipTimeout) clearTimeout(this.state.tooltipTimeout);
                  this.mounted &&
                    this.setState({ hovered: false, tooltip: false, tooltipTimeout: undefined });
                }}
              />
              <BackSVG className="btn-background" style={backStyle} />
              {tooltip}
            </div>
            {content}
          </div>
        </div>
      );
    }
  };
}
