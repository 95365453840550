import React from 'react';
import './ChatboxContainer.css';
import { ReactComponent as ChatSVG } from '../icon/chat_bubble.svg';
import Chatbox from '../ChatBox';

interface ChatboxState {
  active: boolean;
}

interface ChatboxProps {
  getAllConfigs: CallableFunction;
}

export default class ChatboxContainer extends React.Component<ChatboxProps> {
  state: ChatboxState = {
    active: false,
  };

  render = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    if (mobile) {
      return (
        <>
          {this.state.active ? (
            <div className="mob-chatbox-window">
              <Chatbox
                closeCallback={() => {
                  this.setState({ active: false });
                }}
                getAllConfigs={this.props.getAllConfigs}
              />
            </div>
          ) : null}
          <ChatSVG
            onClick={() => {
              this.setState({ active: !this.state.active });
            }}
          />
        </>
      );
    }

    return (
      <div className="chatbox-container">
        {this.state.active ? (
          <div className="chatbox-window">
            <Chatbox
              closeCallback={() => {
                this.setState({ active: false });
              }}
              getAllConfigs={this.props.getAllConfigs}
            />
          </div>
        ) : null}
        <ChatSVG
          onClick={() => {
            this.setState({ active: !this.state.active });
          }}
        />
      </div>
    );
  };
}
