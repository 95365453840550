import React, { RefObject } from 'react';
import ReactGA from 'react-ga';
import './modalViewer3d.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { ReactComponent as CloseSVG } from '../../icon/close.svg';
import { ReactComponent as DownloadSVG } from '../../icon/file_download.svg';
import { ReactComponent as MailSVG } from '../../icon/mail.svg';
import { ReactComponent as FullscreenSVG } from '../../icon/open_in_full.svg';
import { ReactComponent as FullscreenCloseSVG } from '../../icon/close_fullscreen.svg';
import { ReactComponent as ExploreSVG } from '../../icon/mouse-control-explore.svg';
import { ReactComponent as MobileExploreSVG } from '../../icon/touch-control-explore.svg';
import { ReactComponent as LeftViewSVG } from '../../icon/3d_left_view.svg';
import { ReactComponent as TopViewSVG } from '../../icon/3d_top_view.svg';
import { ReactComponent as RightViewSVG } from '../../icon/3d_right_view.svg';
import { ReactComponent as NewSVG } from '../../icon/new.svg';
import { ReactComponent as NeuSVG } from '../../icon/neu.svg';
import { ReactComponent as DownSVG } from '../../icon/down.svg';
import { ReactComponent as UpSVG } from '../../icon/expand-more.svg';
import { svgRecolor } from '../../libs/utils';
import { getNameById } from '../../libs/marzipano';
const Viewer3D = require('int_sdk_viewer3d').default;

interface ModalDetProps extends ModalHotspotProps {
  close: CallableFunction;
  active: boolean;
}

interface ViewerState {
  fullscreen: boolean;
  tutorial: boolean;
  loadModel: boolean;
  loaded: boolean;
  showInfo: boolean;
}

export default class Modal extends React.Component<ModalDetProps> {
  viewerRef = React.createRef<any>();
  api: any;
  exploreRef: RefObject<SVGSVGElement> = React.createRef();
  state: ViewerState = {
    fullscreen: false,
    tutorial: true,
    loadModel: false,
    loaded: false,
    showInfo: false,
  };
  glContext: any;

  componentDidMount = () => {
    if (this.exploreRef.current) {
      svgRecolor(this.exploreRef.current);
    }
  };

  componentDidUpdate = (prevProps: ModalDetProps) => {
    if (prevProps.active !== this.props.active) {
      this.setState({ loadModel: this.props.active, loaded: false });
      if (!this.props.active && this.viewerRef.current) {
        this.viewerRef.current.renderManager.renderer
          .getContext()
          .getExtension('WEBGL_lose_context')
          .loseContext();
      }
    }
    if (this.exploreRef.current) {
      svgRecolor(this.exploreRef.current);
    }
  };

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const dataSource = {
      type: this.props.info.viewerSource?.type,
      url: './models/' + this.props.info.viewerSource?.url,
      path: this.props.info.viewerSource?.path,
      enableWireframe: this.props.info.viewerSource?.enableWireframe,
    };

    const features = this.props.info.modelData?.features
      ? this.props.info.modelData.features.map((f, index) => {
          return (
            <div key={'feature-' + index} className="feature-container">
              <h3>{f.feat}</h3>
              <p>{f.desc}</p>
            </div>
          );
        })
      : null;

    let downloadBtns: any;
    if (Array.isArray(this.props.info.modelData?.link)) {
      downloadBtns = this.props.info.modelData?.link.map((l, index) => {
        return (
          <DownloadSVG
            key={`dwnld-${index}`}
            title={this.props.info.title.split('-')[index]}
            onClick={() => {
              ReactGA.event({
                category: 'Modal Hotspot',
                action: `Download`,
                label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
              });
              window.open(l);
            }}
          />
        );
      });
    } else {
      downloadBtns = (
        <DownloadSVG
          onClick={() => {
            if (!Array.isArray(this.props.info.modelData?.link)) {
              ReactGA.event({
                category: 'Modal Hotspot',
                action: `Download`,
                label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
              });
              window.open(this.props.info.modelData?.link);
            }
          }}
        />
      );
    }

    if (mobile) {
      return (
        <div
          className="viewer-modal-mobile"
          style={this.state.fullscreen ? { transform: 'none' } : {}}
        >
          <div
            className={
              this.state.fullscreen
                ? 'viewer-container-mobile fullscreen'
                : 'viewer-container-mobile'
            }
          >
            <img className="bg" alt="" src={'./assets/img/viewer_bg.png'} />
            {this.state.loaded && this.state.tutorial && (
              <div
                className="tutorial-screen-mobile"
                onMouseDown={() => {
                  this.state.loaded && this.state.tutorial && this.setState({ tutorial: false });
                }}
              >
                <MobileExploreSVG ref={this.exploreRef} />
              </div>
            )}
            {!this.state.loaded && this.state.loadModel && (
              <div className="loader">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 149.48">
                  <path
                    d="M86 74.48H43l21.5-37.24L86 0l21.5 37.24L129 74.48z"
                    className="tri one"
                  />
                  <path
                    d="M129 149.48H86l21.5-37.24L129 75l21.5 37.24 21.5 37.24z"
                    className="tri three"
                  />
                  <path
                    d="M43 149.22H0l21.5-37.24L43 74.74l21.5 37.24L86 149.22z"
                    className="tri two"
                  />
                </svg>
                <label className="pb">Powered By</label>
                <img
                  className="av-logo"
                  src="./assets/img/alive_vision_by_3decide_white.png"
                  alt=""
                ></img>
              </div>
            )}
            {this.state.loadModel && (
              <Viewer3D
                ref={this.viewerRef}
                dataSource={dataSource}
                renderer={{ showStats: false, ratio: 1.75 }}
                camera={{
                  fov: 75,
                  enablePan: false,
                  minDistance: 100,
                  scrollZoomToCursor: false,
                  rotateSpeed: 0.5,
                  initialPosition: { x: 100, y: 100, z: 100 },
                }}
                light={{ intensity: 1.5 }}
                scenes={{
                  onSceneReady: (api: any, scene: any) => {
                    this.api = api;
                    this.api
                      .getCameraAPI()
                      .setPosition({ x: 100, y: 100, z: 100 }, { type: 'linear', duration: 1000 });
                    this.setState({ loaded: true });
                  },
                }}
              />
            )}
            <div className="viewer-toolbar">
              {this.state.fullscreen ? (
                <FullscreenCloseSVG
                  onClick={() => {
                    this.state.loaded && this.setState({ fullscreen: false });
                  }}
                />
              ) : (
                <FullscreenSVG
                  onClick={() => {
                    this.state.loaded && this.setState({ fullscreen: true });
                  }}
                />
              )}
            </div>
            <div className="viewer-perspectives">
              <TopViewSVG
                onClick={() => {
                  if (this.api) {
                    ReactGA.event({
                      category: 'Modal Hotspot',
                      action: `Select Top View`,
                      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                    });
                    this.state.loaded &&
                      this.api
                        .getCameraAPI()
                        .setPosition({ x: 0, y: 175, z: 1 }, { type: 'linear', duration: 1000 });
                  }
                }}
              />
              <LeftViewSVG
                onClick={() => {
                  if (this.api) {
                    ReactGA.event({
                      category: 'Modal Hotspot',
                      action: `Select Left View`,
                      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                    });
                    this.state.loaded &&
                      this.api
                        .getCameraAPI()
                        .setPosition({ x: 0, y: 1, z: 175 }, { type: 'linear', duration: 1000 });
                  }
                }}
              />
              <RightViewSVG
                onClick={() => {
                  if (this.api) {
                    ReactGA.event({
                      category: 'Modal Hotspot',
                      action: `Select Right View`,
                      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                    });
                    this.state.loaded &&
                      this.api
                        .getCameraAPI()
                        .setPosition({ x: 175, y: 1, z: 0 }, { type: 'linear', duration: 1000 });
                  }
                }}
              />
            </div>
          </div>
          <div className="viewer-info-mobile" style={this.state.showInfo ? { height: '85%' } : {}}>
            <div
              className="header-mobile"
              style={this.state.showInfo ? { height: '25%' } : {}}
              onClick={() => {
                this.setState({ showInfo: !this.state.showInfo });
              }}
            >
              {this.state.showInfo ? <DownSVG className="arrow" /> : <UpSVG className="arrow" />}
              <h1>
                {this.props.info.title}
                {this.props.info.modelData?.new && this.props.info.modelData?.new !== 'neu' && (
                  <NewSVG className="new" />
                )}
                {this.props.info.modelData?.new === 'neu' && <NeuSVG className="new" />}
              </h1>
              <div className="header-btns-mobile">
                {this.props.info.modelData?.link && downloadBtns}
                <MailSVG
                  onClick={() => {
                    ReactGA.event({
                      category: 'Modal Hotspot',
                      action: `Sent Email`,
                      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                    });
                    window.open(`mailto:${this.props.info.modelData?.email}`);
                  }}
                />
              </div>
            </div>
            {this.state.showInfo && (
              <div className="info-mobile">
                <p className="specs-description">{this.props.info.description}</p>
                <h2>{this.props.info.modelData?.mainFeatures}</h2>
                {features}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="viewer-modal" style={this.state.fullscreen ? { transform: 'none' } : {}}>
          <div className="viewer-body">
            <div
              className={this.state.fullscreen ? 'viewer-container fullscreen' : 'viewer-container'}
            >
              {!this.state.loaded && this.state.loadModel && (
                <div className="loader">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 149.48">
                    <path
                      d="M86 74.48H43l21.5-37.24L86 0l21.5 37.24L129 74.48z"
                      className="tri one"
                    />
                    <path
                      d="M129 149.48H86l21.5-37.24L129 75l21.5 37.24 21.5 37.24z"
                      className="tri three"
                    />
                    <path
                      d="M43 149.22H0l21.5-37.24L43 74.74l21.5 37.24L86 149.22z"
                      className="tri two"
                    />
                  </svg>
                  <label className="pb">Powered By</label>
                  <img
                    className="av-logo"
                    src="./assets/img/alive_vision_by_3decide_white.png"
                    alt=""
                  ></img>
                </div>
              )}
              <img className="bg" alt="" src={'./assets/img/viewer_bg.png'} />
              {this.state.loaded && this.state.tutorial && (
                <div
                  className="tutorial-screen"
                  onMouseDown={() => {
                    this.state.loaded && this.state.tutorial && this.setState({ tutorial: false });
                  }}
                >
                  <ExploreSVG ref={this.exploreRef} />
                  <p>{`Click & hold \n to rotate`}</p>
                </div>
              )}
              {this.state.loadModel && (
                <Viewer3D
                  ref={this.viewerRef}
                  dataSource={dataSource}
                  renderer={{ showStats: false, ratio: 1.25 }}
                  camera={{
                    fov: 75,
                    enablePan: false,
                    minDistance: 80,
                    scrollZoomToCursor: false,
                    rotateSpeed: 0.5,
                    initialPosition: { x: 85, y: 85, z: 85 },
                  }}
                  light={{ intensity: 1.5 }}
                  scenes={{
                    onSceneReady: (api: any, scene: any) => {
                      this.api = api;
                      this.api
                        .getCameraAPI()
                        .setPosition({ x: 85, y: 85, z: 85 }, { type: 'linear', duration: 1000 });
                      this.setState({ loaded: true });
                    },
                  }}
                />
              )}
              <div className="viewer-toolbar">
                {this.state.fullscreen ? (
                  <FullscreenCloseSVG
                    onClick={() => {
                      this.state.loaded && this.setState({ fullscreen: false });
                    }}
                  />
                ) : (
                  <FullscreenSVG
                    onClick={() => {
                      this.state.loaded && this.setState({ fullscreen: true });
                    }}
                  />
                )}
              </div>
              <div className="viewer-perspectives">
                <TopViewSVG
                  onClick={() => {
                    if (this.api) {
                      ReactGA.event({
                        category: 'Modal Hotspot',
                        action: `Select Top View`,
                        label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                      });
                      this.state.loaded &&
                        this.api
                          .getCameraAPI()
                          .setPosition({ x: 0, y: 150, z: 1 }, { type: 'linear', duration: 1000 });
                    }
                  }}
                />
                <LeftViewSVG
                  onClick={() => {
                    if (this.api) {
                      ReactGA.event({
                        category: 'Modal Hotspot',
                        action: `Select Left View`,
                        label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                      });
                      this.state.loaded &&
                        this.api
                          .getCameraAPI()
                          .setPosition({ x: 0, y: 1, z: 150 }, { type: 'linear', duration: 1000 });
                    }
                  }}
                />
                <RightViewSVG
                  onClick={() => {
                    if (this.api) {
                      ReactGA.event({
                        category: 'Modal Hotspot',
                        action: `Select Right View`,
                        label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                      });
                      this.state.loaded &&
                        this.api
                          .getCameraAPI()
                          .setPosition({ x: 150, y: 1, z: 0 }, { type: 'linear', duration: 1000 });
                    }
                  }}
                />
              </div>
            </div>
            <div className="specs-container">
              <div className="specs-head">
                <div className="head-title">
                  <h1>{this.props.info.title}</h1>
                  {this.props.info.modelData?.new && this.props.info.modelData?.new !== 'neu' && (
                    <NewSVG />
                  )}
                  {this.props.info.modelData?.new === 'neu' && <NeuSVG />}
                  {/*this.props.info.modelData?.new && (
                    <div className="new-label">{this.props.info.modelData.new}</div>
                  )*/}
                </div>
                <div className="head-btns">
                  {this.props.info.modelData?.link && downloadBtns}
                  <MailSVG
                    onClick={() => {
                      ReactGA.event({
                        category: 'Modal Hotspot',
                        action: `Sent Email`,
                        label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
                      });
                      window.open(`mailto:${this.props.info.modelData?.email}`);
                    }}
                  />
                  <CloseSVG
                    onClick={() => {
                      this.props.close();
                    }}
                  />
                </div>
              </div>
              <div className="specs-body">
                <p className="specs-description">{this.props.info.description}</p>
                <h2>{this.props.info.modelData?.mainFeatures}</h2>
                {features}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
