import React, { FunctionComponent, useEffect, useState } from 'react';
import { GlobalVars } from '../libs/utils';
import Participant from './Participant';

export interface Props {
  room: any;
  handleLogout: any;
  live: boolean;
  participantsLimit: number;
}

const Room: FunctionComponent<Props> = ({ room, participantsLimit, live }) => {
  const [participants, setParticipants] = useState<any>([]);
  //const [creatorIndex, setCreatorIndex] = useState<number>(-1);
  /*const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );*/

  /*useEffect(() => {
    if (creatorIndex < 0 && participants.length > 0 && !GlobalVars.admin) {
      setCreatorIndex(participants.length - 1);
    }
    console.log(creatorIndex);
  }, [creatorIndex, participants]);*/

  useEffect(() => {
    const participantConnected = (participant: any) => {
      let addParticipant = true;
      participants.forEach((p: any) => {
        if (p.sid === participant.sid) {
          addParticipant = false;
        }
      });
      if (
        participant.state === 'connected' &&
        participants.length < participantsLimit &&
        addParticipant
      ) {
        setParticipants((prevParticipants: any) => [...prevParticipants, participant]);
        if (GlobalVars.socket) {
          GlobalVars.socket.emit('participants', participants.length + 1);
        }
      }
    };

    const participantDisconnected = (participant: any) => {
      setParticipants((prevParticipants: any) =>
        prevParticipants.filter(
          (p: any) => p !== participant && p.identity !== room.localParticipant.identity,
        ),
      );
      if (GlobalVars.socket) {
        GlobalVars.socket.emit('participants', participants.length - 1);
      }
    };

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room, participants, participantsLimit]);

  useEffect(() => {
    const disconnect = () => {
      if (room.state !== 'disconnected') room.disconnect();
      const closeCallEvent = new Event('closeCall');
      window.dispatchEvent(closeCallEvent);
      /*const stopLiveEvent = new Event('stopLive');
      window.dispatchEvent(stopLiveEvent);*/
    };

    room.on('disconnected', disconnect);

    return () => {
      room.off('disconnected', disconnect);
      room.disconnect();
    };
  }, [room]);

  let remoteParticipants = participants.map((participant: any) => (
    <Participant key={participant.sid} participant={participant} live={live} />
  ));

  /*if (!GlobalVars.admin && !mobile) {
    remoteParticipants = remoteParticipants.slice(creatorIndex, creatorIndex + 1);
  }

  if (mobile) {
    remoteParticipants = remoteParticipants.slice(0, 3);
  }*/

  return (
    <div className="room">
      {
        //<button onClick={handleLogout}>Log out</button>
      }
      <div className="local-participant">
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            isLocal={true}
            live={live}
          />
        ) : (
          ''
        )}
      </div>

      <div className="remote-participants">{remoteParticipants}</div>
    </div>
  );
};

export default Room;
