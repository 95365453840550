import React from 'react';
import './header.scss';

export interface HeaderProps {
  closeCallback: CallableFunction;
}

const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);

function Header(props: HeaderProps) {
  return (
    <div className={mobile ? 'mob-header-container' : 'header-container'}>
      <div className="title-container typo-chat-title">Chat</div>
      <div className="close-box-container">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          onClick={() => {
            props.closeCallback();
          }}
        >
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
}

export default Header;
