/**
 * Gets a hash that represents the current camera position and orientation/target.
 * @returns {*|String} Base64 string (url-friendly) with the hash. */

import { Socket } from 'socket.io-client';

/*export const getCameraState() {
 
     const position = this.getCameraPosition();
     const target = this.getCameraTarget();
 
     const buf = Buffer.alloc(6 * 4);  //4 bytes for each value
     const valueList = [position.x, position.y, position.z, target.x, target.y, target.z];
 
     for (let i = 0; i < valueList.length; i++) {
       buf.writeFloatBE(valueList[i], i * 4);
 
     }
 
     const base64String = buf.toString('base64');
 
     return UrlEncoder.base64EncodeUrl(base64String);
   }*/

export class GlobalVars {
  public static socket: Socket | undefined;
  public static room: number | undefined;
  public static chat: boolean | undefined;
  public static controller: boolean | undefined;
  public static admin: boolean | undefined;
  public static identity: string | undefined;
  public static hash: string | undefined;
  public static startMuted: boolean = false;
  public static startOff: boolean = false;
  public static reset = () => {
    GlobalVars.socket = undefined;
    GlobalVars.room = undefined;
    GlobalVars.chat = undefined;
    GlobalVars.controller = undefined;
    GlobalVars.admin = undefined;
    GlobalVars.identity = undefined;
    GlobalVars.hash = undefined;
  };
}

export const copyLiveUrl = (
  roomId: number,
  success: CallableFunction,
  failure: CallableFunction,
) => {
  GlobalVars.hash = GlobalVars.hash ? GlobalVars.hash : encodeNumber(roomId);
  const port = window.location.port ? ':' + window.location.port : '';
  navigator.clipboard
    .writeText(
      window.location.protocol +
        '//' +
        window.location.hostname +
        port +
        window.location.pathname +
        '?live=' +
        GlobalVars.hash,
    )
    .then(
      () => {
        success();
      },
      () => {
        failure();
      },
    );
};

export const getLiveUrl = (roomId: number) => {
  GlobalVars.hash = GlobalVars.hash ? GlobalVars.hash : encodeNumber(roomId);
  const port = window.location.port ? ':' + window.location.port : '';
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    port +
    window.location.pathname +
    '?live=' +
    GlobalVars.hash
  );
};

export const getCurrentUrl = () => {
  const port = window.location.port ? ':' + window.location.port : '';
  return (
    window.location.protocol + '//' + window.location.hostname + port + window.location.pathname
  );
};

export const encodeNumber = (roomId: number) => {
  const bytesList = [4, 4];
  const bytes = bytesList.reduce((a, b) => a + b, 0);
  const buf = Buffer.alloc(bytes);
  //const valueList = [roomId];
  buf.writeFloatBE(Math.random() * 1000, 0);
  buf.writeFloatBE(roomId, 4);
  const base64String = buf.toString('base64');
  return encodeURIComponent(base64String);
};

export const decodeNumber = (hash: string) => {
  const base64Values = decodeURIComponent(hash);
  const buf = Buffer.from(base64Values, 'base64');
  return buf.readFloatBE(4).toFixed(0);
};

const nParams = 9;

export const encodeHash = (
  mapping: string,
  sector: string,
  space: string,
  yaw: number,
  pitch: number,
  fov: number,
  timelapse: string,
  hotspot: string,
  focus: string,
) => {
  const bytesList = [
    4,
    4,
    4,
    4,
    4,
    4,
    4,
    4,
    4,
    mapping.length,
    sector.length,
    space.length,
    4,
    4,
    4,
    timelapse.length,
    hotspot.length,
    focus.length,
  ];
  const bytes = bytesList.reduce((a, b) => a + b, 0);
  const buf = Buffer.alloc(bytes);
  const valueList = [
    mapping.length,
    sector.length,
    space.length,
    4,
    4,
    4,
    timelapse.length,
    hotspot.length,
    focus.length,
    mapping,
    sector,
    space,
    yaw,
    pitch,
    fov,
    timelapse,
    hotspot,
    focus,
  ];

  for (let i = 0; i < valueList.length; i++) {
    const val = valueList[i];
    let offset = 0;
    if (i > 0) {
      for (let j = 0; j < i; j++) {
        offset += bytesList[j];
      }
    }
    switch (typeof val) {
      case 'string':
        buf.write(val, offset);
        break;
      case 'number':
        buf.writeFloatBE(val, offset);
        break;
    }
  }

  const base64String = buf.toString('base64');

  return encodeURIComponent(base64String);
};

export const degToRad = (degrees?: string) => {
  if (!degrees) return 0;

  let matches = degrees.match(/(\d+)/);

  if (matches) {
    let deg = Number(matches[0]);
    return (deg * Math.PI) / 180;
  }
  return 0;
};

/**
 * Sets the current camera position and orientation/target from a hash.
 * @param state {*|String} Base64 string (url-friendly) with the hash. */

/*export const setCameraState(state) {
     const base64State = UrlEncoder.base64DecodeUrl(state);
      const buf = Buffer.from(base64State, 'base64');
      const valueList = [];
      for (let i = 0; i < 6; i++) {
        valueList.push(buf.readFloatBE(i * 4));
      }
      this.setCameraPosition(new THREE.Vector3(valueList[0], valueList[1], valueList[2]));
      this.setCameraTarget(new THREE.Vector3(valueList[3], valueList[4],valueList[5]));
    } */

export const decodeHash = (hash: string) => {
  let bytesList: number[] = [];
  let valueList = [];
  const base64Values = decodeURIComponent(hash);
  const buf = Buffer.from(base64Values, 'base64');
  for (let i = 0; i < nParams; i++) {
    bytesList.push(buf.readFloatBE(i * 4));
  }
  for (let i = 0; i < nParams; i++) {
    let start = nParams * 4;
    for (let j = 0; j < i; j++) {
      start += bytesList[j];
    }
    const end = start + bytesList[i];
    if (i === 3 || i === 4 || i === 5) {
      valueList.push(buf.readFloatBE(start).toFixed(5));
    } else {
      valueList.push(buf.slice(start, end).toString());
    }
  }
  return {
    mapping: valueList[0],
    sector: valueList[1],
    space: valueList[2],
    yaw: valueList[3],
    pitch: valueList[4],
    fov: valueList[5],
    timelapse: valueList[6],
    hotspot: valueList[7],
    focus: valueList[8] === 'true',
  };
};

export const arrayEquals = (a: any | any[], b: any | any[]) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.every((val, index) => val === b[index]);
  } else {
    return false;
  }
};

export const getCookie = (cname: string) => {
  const tmp = document.cookie.split(';');
  for (let i = 0; i < tmp.length; i++) {
    if (tmp[i].trim().startsWith(cname + '=')) {
      return tmp[i].split('=')[1].trim();
    }
  }
  return '';
};

export const setCookie = (cname: string, value: string) => {
  document.cookie = cname + '=' + value;
};

export const isEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 0,
        g: 0,
        b: 0,
      };
};

export const svgRecolor = (element: any) => {
  let chldrn = element.children;
  if (chldrn) {
    for (let i = 0; i < chldrn.length; i++) {
      if (chldrn[i].children.length > 0) {
        svgRecolor(chldrn[i]);
      } else {
        if (chldrn[i].getAttribute('fill') && chldrn[i].getAttribute('fill') !== 'white') {
          chldrn[i].setAttribute('fill', 'rgb(var(--main-color))');
        }
        if (chldrn[i].getAttribute('stroke') && chldrn[i].getAttribute('stroke') !== 'white') {
          chldrn[i].setAttribute('stroke', 'rgb(var(--main-color))');
        }
      }
    }
  }
};

export const getToken = () => {
  let token: string | undefined = undefined;
  if (document.cookie) {
    token = document.cookie
      .split('; ')
      .find((row) => row.startsWith('jwttoken='))
      ?.split('=')[1];
  }
  return token ? token : '';
};
