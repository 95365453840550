import React from 'react';
import ReactGA from 'react-ga';
import './MainBar.css';
import { MainBarProps, MainBarState } from '../types/mainBar';
import { ReactSVG } from 'react-svg';
import leftArrow from '../icon/left.svg';
import rightArrow from '../icon/right.svg';
import upArrow from '../icon/expand-more.svg';
import downArrow from '../icon/down.svg';
import playBtn from '../icon/play.svg';
import pauseBtn from '../icon/pause-2.svg';
//import { ReactComponent as SlideshowSVG } from '../icon/slideshow.svg';
import { ReactComponent as MapSVG } from '../icon/room.svg';
import { ReactComponent as GallerySVG } from '../icon/collections.svg';
import { ReactComponent as LeftSVG } from '../icon/left.svg';
import { ReactComponent as RightSVG } from '../icon/right.svg';
import { ReactComponent as PlaySVG } from '../icon/play.svg';
import { ReactComponent as PauseSVG } from '../icon/pause-2.svg';
import { ReactComponent as CloseSVG } from '../icon/close.svg';
import { ReactComponent as InfoSVG } from '../icon/info.svg';
import { ReactComponent as ShareSVG } from '../icon/share.svg';
import { ReactComponent as TimelapseSVG } from '../icon/timelapse.svg';
import { ReactComponent as PointAndAskSVG } from '../icon/poin-ask.svg';
import { ReactComponent as BackSVG } from '../icon/back-oval.svg';
import { ReactComponent as DollhouseSVG } from '../icon/dollhouse.svg';
import { ReactComponent as Arrow360SVG } from '../icon/360.svg';
import { stopAutoRotate, startAutoRotate, position } from '../libs/marzipano';
import PanoInfo from './PanoInfo';
import ShareLink from './ShareLink';
import { encodeHash, GlobalVars } from '../libs/utils';
import Timelapse from './TimelapseBar';
import DayNight from './DayNight';
import DropdownCircuits from './DropdownCircuits';
import DecorSelect from './DecorSelect';
//import LiveMode from './LiveMode';
import ChatboxContainer from './ChatboxContainer';
import { LandingPageConfiguration } from '../types/landingPage';
import Group from '../components/group';

export default class mainBar extends React.Component<MainBarProps> {
  state: MainBarState = {
    thumbnailsMenu: false,
    mapDisplayed: false,
    slideshowMenu: false,
    infoDisplayed: false,
    sharedLink: false,
    timelapseMenu: false,
    timelapseAutoplay: false,
  };

  componentDidMount = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      window.addEventListener('deactivateMap', (() => {
        this.setState({ mapDisplayed: false });
      }) as EventListener);
    }
  };

  handlePointAndAsk = () => {
    this.closeAll();
    this.props.pointAndAskData.toggle(!this.props.pointAndAskData.active);
  };

  closeAll = () => {
    this.setState({
      thumbnailsMenu: false,
      mapDisplayed: false,
      slideshowMenu: false,
      infoDisplayed: false,
    });
    const displayMapEvent = new CustomEvent('displayMap', {
      detail: { display: false },
    });
    window.dispatchEvent(displayMapEvent);
    if (this.props.pointAndAskData.active) {
      this.props.pointAndAskData.toggle(false);
    }
  };

  displayMap = () => {
    const display = !this.state.mapDisplayed;
    const displayMapEvent = new CustomEvent('displayMap', {
      detail: { display: display },
    });
    window.dispatchEvent(displayMapEvent);
    this.setState({ mapDisplayed: display });
    if (display) {
      stopAutoRotate();
    } else {
      if (this.props.mode === 'normal') {
        startAutoRotate();
      }
    }
  };

  displayThumbnailsMenu = () => {
    const display = !this.state.thumbnailsMenu;
    this.setState({ thumbnailsMenu: display });
    if (display) {
      ReactGA.event({
        category: 'Navbar',
        action: `Open Thumbnails`,
        label: `${this.props.sceneName}`,
      });
      stopAutoRotate();
    } else {
      ReactGA.event({
        category: 'Navbar',
        action: `Close Thumbnails`,
        label: `${this.props.sceneName}`,
      });
      if (this.props.mode === 'normal') {
        startAutoRotate();
      }
    }
  };

  displayInfoPanel = () => {
    const display = !this.state.infoDisplayed;
    this.setState({ infoDisplayed: display });
    if (display) {
      stopAutoRotate();
    } else {
      if (this.props.mode === 'normal') {
        startAutoRotate();
      }
    }
  };

  shareLink = () => {
    this.closeAll();
    const pos = position();
    const scene = Array.isArray(this.props.selectedSceneId)
      ? this.props.selectedSceneId[this.props.timelapseIndex]
      : this.props.selectedSceneId;
    ReactGA.event({
      category: 'Share Link',
      action: 'Clicked',
      label: scene,
    });
    const timelapseIndex = Array.isArray(this.props.selectedSceneId)
      ? this.props.timelapseIndex
      : -1;
    const code = encodeHash(
      this.props.shareLinkData.mapping.split('.')[0],
      this.props.shareLinkData.sector,
      scene,
      pos.yaw,
      pos.pitch,
      pos.fov,
      String(timelapseIndex),
      'test',
      'false',
    );

    navigator.clipboard.writeText(window.location.href + '?loc=' + code).then(
      () => {
        this.setState({ sharedLink: true });
        setTimeout(() => {
          this.setState({ sharedLink: false });
        }, 2000);
      },
      () => {
        let alertLbl = 'Não foi possível copiar o link, por favor veja as definições do browser.';
        switch (this.props.language) {
          case 'English':
            alertLbl =
              'It was not possible to copy link to clipboard, please review your browser settings.';
            break;

          default:
            alertLbl = 'Não foi possível copiar o link, por favor veja as definições do browser.';
            break;
        }
        alert(alertLbl);
      },
    );
  };

  render() {
    const landingPageConfig: LandingPageConfiguration = require('../JSON/landing_page.json');
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let copiedLink = 'Link copiado para a área de transferências';
    let paMessage = (
      <p className="info-pa">
        Duplo click em qualquer lado para <br></br> fazer uma pergunta
      </p>
    );
    let mobilePAMessage = 'Pressione em qualquer lado para fazer uma pergunta';
    switch (this.props.language) {
      case 'English':
        mobilePAMessage = 'Press and hold anywhere to ask a question';
        paMessage = (
          <p className="info-pa">
            Double click anywhere to <br></br> ask a question
          </p>
        );
        copiedLink = 'Link has been copied to clipboard';
        break;

      default:
        mobilePAMessage = 'Pressione em qualquer lado para fazer uma pergunta';
        paMessage = (
          <p className="info-pa">
            Duplo click em qualquer lado para <br></br> fazer uma pergunta
          </p>
        );
        copiedLink = 'Link copiado para a área de transferências';
        break;
    }

    const selectedScene = Array.isArray(this.props.selectedSceneId)
      ? this.props.selectedSceneId[this.props.timelapseIndex]
      : this.props.selectedSceneId;

    const thumbnailsClass = this.state.thumbnailsMenu ? 'thumbnails' : '';

    const thumbnailsBottom = this.state.thumbnailsMenu ? '80.65vh' : undefined;

    const thumbnails = this.props.panoSequence.map((panoId) => {
      const label = this.props.appdata.scenes.find((scene) => scene.id === panoId)?.name;
      const selectedClass = this.props.selectedSceneId === panoId ? 'selected' : '';
      if (this.props.navBarSettings?.thumbnailsInfo) {
        return (
          <div key={`selector-${panoId}`} className="gradient-thumbnail">
            <img
              className={'selector ' + selectedClass}
              src={`./assets/thumbnails/${panoId}.png`}
              alt=""
              onClick={() => {
                const stopDescriptionEvent = new CustomEvent('stopDescription', {
                  detail: {},
                });
                window.dispatchEvent(stopDescriptionEvent);
                this.props.changeScene(panoId, '360');
                this.setState({ thumbnailsMenu: false });
              }}
            ></img>
            <div className="gradient">{label}</div>
          </div>
        );
      } else {
        return (
          <img
            key={`selector-${panoId}`}
            className={'selector '}
            src={`./assets/thumbnails/${panoId}.png`}
            alt=""
            onClick={() => {
              const stopDescriptionEvent = new CustomEvent('stopDescription', {
                detail: {},
              });
              window.dispatchEvent(stopDescriptionEvent);
              this.props.changeScene(panoId, '360');
              this.setState({ thumbnailsMenu: false });
            }}
          />
        );
      }
    });

    const mobileSceneName = this.props.sceneName
      ? this.props.sceneName + ' | ' + this.props.mappingName
      : '';
    const mapClass = this.state.mapDisplayed ? 'mapActive' : '';
    const infoClass = this.state.infoDisplayed ? 'infoActive' : '';
    const mobSharedStyle = this.state.sharedLink
      ? { opacity: 1, transform: 'translate(-50%, -200%)' }
      : {};

    const selectors = Array.isArray(this.props.selectedSceneId)
      ? this.props.selectedSceneId.map((space, index) => {
          const className =
            this.props.timelapseIndex === index ? 'time-selector selected' : 'time-selector';
          return (
            <div
              key={`time-${index}`}
              className={className}
              onClick={() => {
                this.props.timelapseData.select(index);
              }}
            ></div>
          );
        })
      : [];

    const timelapseIndex = Array.isArray(this.props.selectedSceneId)
      ? this.props.timelapseIndex
      : -1;

    let hasHumanizationParam = false;
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const humanization = urlParams.get('humanization');
      if (humanization && humanization === 'true') hasHumanizationParam = true;
    }

    if (mobile) {
      if (this.state.slideshowMenu) {
        return (
          <div className="mobile-slideshow-menu">
            {this.props.mode !== 'normal' ? (
              <div className="mobile-progress-bar">
                <div className="progress" style={{ width: `${this.props.progress}%` }}></div>
              </div>
            ) : null}
            <CloseSVG
              className="close"
              onClick={() => {
                this.props.changeMode('normal');
                this.setState({ slideshowMenu: false });
              }}
            />
            <p>{this.props.label}</p>
            <LeftSVG
              onClick={() => {
                ReactGA.event({
                  category: 'Navbar',
                  action: `Left`,
                  label: `${this.props.sceneName}`,
                });
                this.props.leftArrowClick();
              }}
            />
            <RightSVG
              onClick={() => {
                ReactGA.event({
                  category: 'Navbar',
                  action: `Right`,
                  label: `${this.props.sceneName}`,
                });
                this.props.rightArrowClick();
              }}
            />
            {this.props.navBarSettings?.autoplay.enabled ? (
              this.props.mode === 'autoplay' ? (
                <PauseSVG
                  className="pause"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Navbar',
                      action: `Pause`,
                      label: `${this.props.sceneName}`,
                    });
                    this.props.playClick();
                  }}
                />
              ) : (
                <PlaySVG
                  onClick={() => {
                    ReactGA.event({
                      category: 'Navbar',
                      action: `Play`,
                      label: `${this.props.sceneName}`,
                    });
                    this.props.playClick();
                  }}
                />
              )
            ) : null}
          </div>
        );
      }

      if (this.state.timelapseMenu) {
        return (
          <div className="mobile-timelapse-menu">
            <CloseSVG
              className="close"
              onClick={() => {
                this.setState({ timelapseMenu: false, timelapseAutoplay: false });
              }}
            />
            <LeftSVG
              onClick={() => {
                this.props.timelapseData.leftArrowClick();
              }}
            />
            <div className="line">{selectors}</div>
            <RightSVG
              onClick={() => {
                this.props.timelapseData.rightArrowClick();
              }}
            />
            {this.state.timelapseAutoplay ? (
              <PauseSVG
                className="pause"
                onClick={() => {
                  this.props.timelapseData.autoplay();
                }}
              />
            ) : (
              <PlaySVG
                className="play"
                onClick={() => {
                  this.props.timelapseData.autoplay();
                }}
              />
            )}
          </div>
        );
      }

      if (this.props.pointAndAskData.active) {
        return (
          <div className="mobile-pa-bar">
            <CloseSVG
              className="mobile-close-pa"
              onClick={() => {
                this.props.pointAndAskData.toggle(false);
              }}
            />
            <p className="mobile-info-pa">{mobilePAMessage}</p>
          </div>
        );
      }

      return (
        <div>
          {this.props.panoInfo ? (
            <PanoInfo
              title={this.props.panoInfo.title}
              description={this.props.panoInfo.description}
              bottom={thumbnailsBottom}
              mobileActive={this.state.infoDisplayed}
              displayInfo={this.displayInfoPanel}
              language={this.props.language}
              sceneId={this.props.selectedSceneId}
              timelapseIndex={this.props.timelapseIndex}
            />
          ) : null}
          {this.props.navBarSettings?.showShareLink ? (
            <div className="mobile-shared" style={mobSharedStyle}>
              {copiedLink}
            </div>
          ) : null}

          <div className="mobile-navBar">
            {this.props.circuitsProps ? (
              <DropdownCircuits
                currentMapping={this.props.circuitsProps.currentMapping}
                circuitsList={this.props.circuitsProps.circuitsList}
                changeCircuit={this.props.circuitsProps.changeCircuit}
                night={this.props.circuitsProps.night}
                language={this.props.language || ''}
              />
            ) : null}
            <p>{mobileSceneName}</p>
            {this.props.panoInfo ? (
              <InfoSVG
                className={infoClass}
                onClick={() => {
                  this.closeAll();
                  this.displayInfoPanel();
                }}
              />
            ) : null}
            {this.props.navBarSettings?.pointAndAsk?.enabled ? (
              <PointAndAskSVG
                onClick={() => {
                  this.closeAll();
                  this.handlePointAndAsk();
                }}
              />
            ) : null}
            {this.props.navBarSettings?.showShareLink ? (
              <ShareSVG
                className="share-icon"
                onClick={() => {
                  this.shareLink();
                }}
              />
            ) : null}
            {this.props.dayNightData.night !== undefined && !this.props.liveModeData.videochat ? (
              <DayNight
                night={this.props.dayNightData.night}
                toggleNight={this.props.dayNightData.toggleNight}
              />
            ) : null}
            {this.props.decorSelectData.decorDataPath.length > 0 ? (
              <DecorSelect
                decorDataPath={this.props.decorSelectData.decorDataPath}
                goToDecor={this.props.decorSelectData.goToDecor}
                currentMappingPath={this.props.decorSelectData.currentMappingPath}
                language={this.props.language || ''}
              />
            ) : null}
            {this.props.navBarSettings?.thumbnails ? (
              <GallerySVG
                className={thumbnailsClass}
                onClick={() => {
                  this.closeAll();
                  this.displayThumbnailsMenu();
                }}
              />
            ) : null}
            {Array.isArray(this.props.selectedSceneId) ? (
              <TimelapseSVG
                style={{ width: '5.2vw' }}
                onClick={() => {
                  this.closeAll();
                  this.setState({ timelapseMenu: true });
                }}
              />
            ) : null}
            {this.props.navBarSettings?.enabled && (
              <PlaySVG
                onClick={() => {
                  this.closeAll();
                  this.props.rightArrowClick();
                  this.setState({ slideshowMenu: true });
                }}
              />
            )}
            {this.props.modelViewerData.enabled ? (
              <div
                className="dollhouse"
                onClick={() => {
                  this.props.modelViewerData.handleDollHouse();
                }}
              >
                {this.props.modelViewerData.showMarzipano ? <DollhouseSVG /> : <Arrow360SVG />}
              </div>
            ) : null}
            {((this.props.navBarSettings?.liveMode !== false && landingPageConfig.enableLiveMode) ||
              hasHumanizationParam) &&
              !this.props.liveModeData.videochat && <Group />}
            {this.props.navBarSettings?.enabled && landingPageConfig.chat?.enabled ? (
              <ChatboxContainer getAllConfigs={this.props.getAllConfigs} />
            ) : null}
            {this.props.mapEnabled ? (
              <MapSVG
                style={{ width: '3.6vw' }}
                className={mapClass}
                onClick={() => {
                  this.closeAll();
                  this.displayMap();
                }}
              />
            ) : null}
          </div>
          {this.props.navBarSettings?.thumbnails && this.state.thumbnailsMenu ? (
            <div className="mobile-thumbnails-menu">{thumbnails}</div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          <div className="toolbar" style={{ top: thumbnailsBottom }}>
            {this.props.panoInfo ? (
              <PanoInfo
                title={this.props.panoInfo.title}
                description={this.props.panoInfo.description}
                bottom={thumbnailsBottom}
                mobileActive={false}
                displayInfo={this.displayInfoPanel}
                language={this.props.language}
                sceneId={this.props.selectedSceneId}
                timelapseIndex={this.props.timelapseIndex}
              />
            ) : null}
            {this.props.navBarSettings?.showShareLink ? (
              <ShareLink
                mapping={this.props.shareLinkData.mapping}
                sector={this.props.shareLinkData.sector}
                spaceId={selectedScene}
                timelapseIndex={timelapseIndex}
                language={this.props.language}
              />
            ) : null}
            {this.props.modelViewerData.enabled ? (
              <div
                className="dollhouse"
                onClick={() => {
                  this.props.modelViewerData.handleDollHouse();
                }}
              >
                {this.props.modelViewerData.showMarzipano ? <DollhouseSVG /> : <Arrow360SVG />}
              </div>
            ) : null}
            {Array.isArray(this.props.selectedSceneId) ? (
              <Timelapse
                closeAll={this.closeAll}
                selectedSpaces={this.props.selectedSceneId}
                timelapseIndex={this.props.timelapseIndex}
                rightArrowClick={this.props.timelapseData.rightArrowClick}
                leftArrowClick={this.props.timelapseData.leftArrowClick}
                select={this.props.timelapseData.select}
                autoplay={this.props.timelapseData.autoplay}
              />
            ) : null}
            {this.props.navBarSettings?.pointAndAsk?.enabled ? (
              <div className="pa">
                <PointAndAskSVG className="svg-pa" />
                <BackSVG
                  className="back-pa"
                  onClick={() => {
                    this.closeAll();
                    this.handlePointAndAsk();
                  }}
                />
                {this.props.pointAndAskData.active ? (
                  <div>
                    <CloseSVG
                      className="close-pa"
                      onClick={() => {
                        this.props.pointAndAskData.toggle(false);
                      }}
                    />
                    {paMessage}
                  </div>
                ) : null}
              </div>
            ) : null}
            {this.props.dayNightData.night !== undefined && !this.props.liveModeData.videochat ? (
              <DayNight
                night={this.props.dayNightData.night}
                toggleNight={this.props.dayNightData.toggleNight}
              />
            ) : null}
            {/*this.props.navBarSettings?.liveMode !== false && landingPageConfig.enableLiveMode ? (
              <LiveMode
                currentMapping={this.props.currentMapping}
                currentScene={this.props.selectedSceneId}
                currentSectorIdx={this.props.currentSectorIdx}
                live={this.props.liveModeData.live}
                setLive={this.props.liveModeData.setLive}
              />
            ) : null*/}
            {((this.props.navBarSettings?.liveMode !== false && landingPageConfig.enableLiveMode) ||
              hasHumanizationParam) &&
              !this.props.liveModeData.videochat && <Group />}
            {this.props.navBarSettings?.enabled &&
            landingPageConfig.chat?.enabled &&
            GlobalVars.admin === undefined ? (
              <ChatboxContainer getAllConfigs={this.props.getAllConfigs} />
            ) : null}
          </div>
          {this.props.navBarSettings?.enabled && !this.props.pointAndAskData.active ? (
            <div className={'main-bar ' + thumbnailsClass}>
              {
                //<p>{this.props.label}</p>
              }
              <ReactSVG
                renumerateIRIElements={false}
                src={leftArrow}
                onClick={() => {
                  const stopDescriptionEvent = new CustomEvent('stopDescription', {
                    detail: {},
                  });
                  window.dispatchEvent(stopDescriptionEvent);
                  ReactGA.event({
                    category: 'Navbar',
                    action: `Left`,
                    label: `${this.props.sceneName}`,
                  });
                  this.props.leftArrowClick();
                }}
              />
              <ReactSVG
                renumerateIRIElements={false}
                src={rightArrow}
                onClick={() => {
                  const stopDescriptionEvent = new CustomEvent('stopDescription', {
                    detail: {},
                  });
                  window.dispatchEvent(stopDescriptionEvent);
                  ReactGA.event({
                    category: 'Navbar',
                    action: `Right`,
                    label: `${this.props.sceneName}`,
                  });
                  this.props.rightArrowClick();
                }}
              />
              {this.props.navBarSettings.thumbnails ? (
                this.state.thumbnailsMenu ? (
                  <ReactSVG
                    renumerateIRIElements={false}
                    src={downArrow}
                    onClick={() => {
                      const stopDescriptionEvent = new CustomEvent('stopDescription', {
                        detail: {},
                      });
                      window.dispatchEvent(stopDescriptionEvent);
                      ReactGA.event({
                        category: 'Navbar',
                        action: `Close Thumbnails`,
                        label: `${this.props.sceneName}`,
                      });
                      this.setState({ thumbnailsMenu: false });
                    }}
                  />
                ) : (
                  <ReactSVG
                    renumerateIRIElements={false}
                    src={upArrow}
                    onClick={() => {
                      const stopDescriptionEvent = new CustomEvent('stopDescription', {
                        detail: {},
                      });
                      window.dispatchEvent(stopDescriptionEvent);
                      ReactGA.event({
                        category: 'Navbar',
                        action: `Open Thumbnails`,
                        label: `${this.props.sceneName}`,
                      });
                      this.props.changeMode('normal');
                      this.setState({ thumbnailsMenu: true });
                    }}
                  />
                )
              ) : null}
              {this.props.navBarSettings.autoplay.enabled ? (
                this.props.mode === 'autoplay' ? (
                  <ReactSVG
                    renumerateIRIElements={false}
                    src={pauseBtn}
                    onClick={() => {
                      const stopDescriptionEvent = new CustomEvent('stopDescription', {
                        detail: {},
                      });
                      window.dispatchEvent(stopDescriptionEvent);
                      ReactGA.event({
                        category: 'Navbar',
                        action: `Pause`,
                        label: `${this.props.sceneName}`,
                      });
                      this.props.playClick();
                    }}
                  />
                ) : (
                  <ReactSVG
                    renumerateIRIElements={false}
                    src={playBtn}
                    onClick={() => {
                      const stopDescriptionEvent = new CustomEvent('stopDescription', {
                        detail: {},
                      });
                      window.dispatchEvent(stopDescriptionEvent);
                      ReactGA.event({
                        category: 'Navbar',
                        action: `Play`,
                        label: `${this.props.sceneName}`,
                      });
                      if (this.props.navBarSettings?.autoplay.enabled) {
                        this.props.playClick();
                      }
                    }}
                  />
                )
              ) : null}
            </div>
          ) : null}
          {this.props.mode !== 'normal' &&
          this.props.navBarSettings?.enabled &&
          this.props.navBarSettings.randomHotspotSequence ? (
            <div className="progress-bar">
              <div className="progress" style={{ width: `${this.props.progress}%` }}></div>
            </div>
          ) : null}
          {this.state.thumbnailsMenu ? <div className="thumbnails-menu">{thumbnails}</div> : null}
        </div>
      );
    }
  }
}
