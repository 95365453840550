import React from 'react';
import './DayNight.css';
import { ReactComponent as SunSVG } from '../icon/sun.svg';
import { ReactComponent as MoonSVG } from '../icon/dark_mode.svg';
import { DayNightProps } from '../types/mainBar';

export default class DayNight extends React.Component<DayNightProps> {
  render = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    if (mobile) {
      if (this.props.night) {
        return <SunSVG onClick={() => this.props.toggleNight(false)} />;
      } else {
        return <MoonSVG onClick={() => this.props.toggleNight(true)} />;
      }
    }

    if (this.props.night) {
      return (
        <div className="tool-day-night">
          <SunSVG onClick={() => this.props.toggleNight(false)} />
        </div>
      );
    } else {
      return (
        <div className="tool-day-night">
          <MoonSVG onClick={() => this.props.toggleNight(true)} />
        </div>
      );
    }
  };
}
