import React, { CSSProperties } from 'react';
import './TimelapseBar.css';
import { ReactComponent as TimelapseSVG } from '../icon/timelapse.svg';
import { ReactComponent as BackSVG } from '../icon/back-oval.svg';
import { ReactComponent as LeftSVG } from '../icon/left.svg';
import { ReactComponent as RightSVG } from '../icon/right.svg';
import { ReactComponent as PlaySVG } from '../icon/play.svg';
import { ReactComponent as PauseSVG } from '../icon/pause-2.svg';
import { TimelapseProps, TimelapseState } from '../types/mainBar';
import { getNameById } from '../libs/marzipano';

export default class Timelapse extends React.Component<TimelapseProps> {
  state: TimelapseState = {
    active: false,
    hovered: false,
    tooltip: false,
    tooltipTimeout: undefined,
    autoplayActive: false,
  };

  componentDidMount = () => {
    window.addEventListener('stopDescription', () => {
      this.setState({
        active: false,
        hovered: false,
        tooltip: false,
        tooltipTimeout: undefined,
        autoplayActive: false,
      });
    });
  };

  toggleAutoplay = () => {
    this.props.autoplay();
    this.setState({ autoplayActive: !this.state.autoplayActive });
  };

  render = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const backStyle: CSSProperties = this.state.hovered ? { opacity: 1 } : { opacity: 0 };

    const barStyle: CSSProperties = this.state.active
      ? { opacity: 1, pointerEvents: 'all' }
      : { opacity: 0, pointerEvents: 'none' };

    const selectors = this.props.selectedSpaces.map((space, index) => {
      const className =
        this.props.timelapseIndex === index ? 'time-selector selected' : 'time-selector';
      return (
        <div
          key={`time-${index}`}
          className={className}
          onClick={() => {
            this.props.select(index);
          }}
        >
          <p>{getNameById(space)}</p>
        </div>
      );
    });

    if (mobile) {
      return null;
    } else {
      return (
        <div>
          <div className="timelapse-bar" style={barStyle}>
            <LeftSVG
              onClick={() => {
                this.props.leftArrowClick();
              }}
            />
            <div className="line">{selectors}</div>
            <RightSVG
              onClick={() => {
                this.props.rightArrowClick();
              }}
            />
            {this.state.autoplayActive ? (
              <PauseSVG onClick={() => this.toggleAutoplay()} />
            ) : (
              <PlaySVG onClick={() => this.toggleAutoplay()} />
            )}
          </div>
          <div className="timelapse-btn">
            <TimelapseSVG
              onClick={() => {
                this.props.closeAll();
                this.setState({ active: !this.state.active });
              }}
              onMouseEnter={() => {
                const tmp = setTimeout(() => this.setState({ tooltip: true }), 800);
                this.setState({ hovered: true, tooltipTimeout: tmp });
              }}
              onMouseLeave={() => {
                if (this.state.tooltipTimeout) clearTimeout(this.state.tooltipTimeout);
                this.setState({ hovered: false, tooltip: false, tooltipTimeout: undefined });
              }}
            />
            <BackSVG className="btn-background" style={backStyle} />
          </div>
        </div>
      );
    }
  };
}
