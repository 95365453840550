import React from 'react';
import './language.css';
import { LanguageProps } from '../types/language';
import { ReactComponent as LanguageSVG } from '../icon/language.svg';
import { ReactComponent as ArrowDownSVG } from '../icon/arrow_drop_down.svg';

export const Language = (props: LanguageProps) => {
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
  const portraitClass = mobile && window.orientation === 0 ? ' vert' : '';
  const languageList = props.languageList.map((language, index) => {
    return (
      <div
        key={`lang-${index}`}
        className="language"
        onClick={() => {
          props.onLanguageSelect(language.label);
        }}
      >
        <img src={language.flagSrc} alt=""></img>
        <p>{language.label}</p>
      </div>
    );
  });
  return (
    <div className={'dropdown-container' + portraitClass}>
      <div className={'dropdown-language'}>
        <LanguageSVG viewBox="-10 0 60 60" />
        <ArrowDownSVG viewBox="10 0 60 60" />
      </div>
      <div className="language-list">{languageList}</div>
    </div>
  );
};
