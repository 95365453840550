import React from 'react';
import { NadirProps } from '../types/hotspot';
import './nadir.css';

export const Nadir = (props: NadirProps) => {
  return (
    <div
      id={props.id}
      style={{ display: 'block', cursor: 'pointer' }}
      onClick={() => {
        window.open(props.click);
      }}
    >
      <div
        className="nadirHS"
        style={{
          backgroundImage: `url(${props.backgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '1050px 1050px',
          backgroundPosition: 'center',
        }}
      ></div>
    </div>
  );
};
