import React, { useState, FunctionComponent } from 'react';
import './group.css';
import { ReactComponent as GroupSVG } from '../icon/groups.svg';
import { getToken, GlobalVars } from '../libs/utils';
import { io } from 'socket.io-client';
import Invitation from './invitation';
import Login from './login';

/*const startCall = () => {
  if (GlobalVars.socket) {
    if (GlobalVars.room) {
      const callEvent = new Event('groupCall');
      window.dispatchEvent(callEvent);
    } else {
      let password = window.prompt('Password para utilização do video chat');
      GlobalVars.socket?.emit('enterPassword', password);
      GlobalVars.socket?.on('enterResult', (result: boolean) => {
        if (result) {
          GlobalVars.socket?.emit('createRoom');
          GlobalVars.socket?.on('roomCreated', (roomId: number) => {
            GlobalVars.room = roomId;
            GlobalVars.admin = true;
            const callEvent = new Event('groupCall');
            window.dispatchEvent(callEvent);
            copyToClipboard(roomId);
          });
        }
      });
    }
  } else {
    GlobalVars.socket = io('https://twilio.alive.vision');
    GlobalVars.socket.on('connect', () => {
      let password = window.prompt('Password para utilização do video chat');
      GlobalVars.socket?.emit('enterPassword', password);
      GlobalVars.socket?.on('enterResult', (result: boolean) => {
        if (result) {
          alert('Password correta');
          GlobalVars.socket?.emit('createRoom');
          GlobalVars.socket?.on('roomCreated', (roomId: number) => {
            GlobalVars.room = roomId;
            GlobalVars.admin = true;
            const callEvent = new Event('groupCall');
            window.dispatchEvent(callEvent);
            copyToClipboard(roomId);
          });
        } else {
          alert('Password errada');
          GlobalVars.socket?.off('enterResult');
        }
      });
    });
  }
};*/

const Group: FunctionComponent = () => {
  const [invite, setInvite] = useState<boolean>(false);
  const [login, setLogin] = useState<boolean>(false);

  const startCall = () => {
    const callEvent = new Event('groupCall');
    window.dispatchEvent(callEvent);
    setInvite(false);
  };

  const afterLogin = () => {
    const token = getToken();
    if (token) {
      GlobalVars.socket = io('https://twilio.alive.vision', {
        query: { token },
      });
      //GlobalVars.socket = io('http://localhost:3000');
      GlobalVars.socket.on('connect', () => {
        GlobalVars.socket?.emit('createRoom');
        GlobalVars.socket?.on('roomCreated', (roomId: number) => {
          GlobalVars.room = roomId;
          GlobalVars.admin = true;
          setLogin(false);
          setInvite(true);
        });
      });
    }
  };

  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  return (
    <>
      {!mobile && (
        <div className="group-container">
          <GroupSVG
            onClick={() => {
              //Abrir invitation ou seja Criar Room temporario e depois abrir, se cancelar pronto, cancela
              if (getToken()) {
                afterLogin();
              } else {
                setLogin(true);
              }
            }}
          />
        </div>
      )}
      {login && (
        <div
          className="invitation-bg"
          onClick={() => {
            setLogin(false);
          }}
        >
          <Login loginCallback={afterLogin} />
        </div>
      )}
      {invite && (
        <div
          className="invitation-bg"
          onClick={() => {
            setInvite(false);
          }}
        >
          <Invitation startCall={startCall} />
        </div>
      )}
    </>
  );
};

export default Group;
