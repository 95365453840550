import React, { FunctionComponent } from 'react';
import './pulse.css';

interface PulseProps {
  stop: CallableFunction;
}

const Pulse: FunctionComponent<PulseProps> = ({ stop }) => {
  /*setTimeout(() => {
    stop();
  }, 1500);*/

  return (
    <div className="pulse-container">
      <div className="pulse">
        <div className="pulse-animation" style={{ animationDelay: '0s' }}></div>
        <div className="pulse-animation" style={{ animationDelay: '-0.5s' }}></div>
        <div className="pulse-animation" style={{ animationDelay: '-1s' }}></div>
      </div>
    </div>
  );
};

export default Pulse;
