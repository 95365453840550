import { MarzipanoTransitionFunctions } from '../types/marzipano';

export const linear = (val: any) => {
  return val;
};

export const transitionFunctions: MarzipanoTransitionFunctions = {
  opacity: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      val = ease(val);
      newScene.layer().setEffects({ opacity: val });
    };
  },

  fromRight: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      val = ease(val);
      newScene.layer().setEffects({ rect: { relativeX: 1 - val } });
    };
  },

  fromTop: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      val = ease(val);
      newScene.layer().setEffects({ rect: { relativeY: -1 + val } });
    };
  },

  fromBottom: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      val = ease(val);
      newScene.layer().setEffects({ rect: { relativeY: 1 - val } });
    };
  },

  width: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      val = ease(val);
      newScene.layer().setEffects({ rect: { relativeWidth: val } });
    };
  },

  fromCenter: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      val = ease(val);
      newScene.layer().setEffects({
        rect: {
          relativeWidth: val,
          relativeHeight: val,
          relativeX: 0.5 - val / 2,
          relativeY: 0.5 - val / 2,
        },
      });
    };
  },

  fromCenterAndOpacity: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      var eased = ease(val);
      newScene.layer().setEffects({
        rect: {
          relativeWidth: eased,
          relativeHeight: eased,
          relativeX: 0.5 - eased / 2,
          relativeY: 0.5 - eased / 2,
        },
        opacity: val,
      });
    };
  },

  fromTopAndOpacity: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      var eased = ease(val);
      newScene.layer().setEffects({ opacity: val, rect: { relativeY: -1 + eased } });
    };
  },

  fromWhite: (ease: CallableFunction) => {
    ease = ease || linear;
    return (val: any, newScene: any) => {
      newScene.layer().setEffects({ colorOffset: [1 - val, 1 - val, 1 - val, 0] });
    };
  },
};
